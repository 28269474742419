import React, {useMemo,useCallback,useEffect, useState, useRef} from "react";
import {PortableText} from '@portabletext/react'
import BlockContent from "@sanity/block-content-to-react";
import sanityClient from "../../client.js";
import { useMediaQuery } from 'react-responsive';
import MuxPlayer from "@mux/mux-player-react/lazy"; 

import './aitherAi.styles.scss';

const AitherAi = () =>{
    const [pageContent, setPageContent] = useState(null)
    const [responsiveState, setResponsiveState] = useState(null)
    const readMoreRef = useRef()
    const readEvenMoreRef = useRef()
    const secondVideoRef = useRef()


const isWide = useMediaQuery({
    query: '(min-width: 1100px)'
})

const isNarrow = useMediaQuery({
    query: '(max-width: 1100px)'
})

const isPhone = useMediaQuery({
    query: '(max-width: 800px)'
})

useEffect(() =>{

    if(isWide){
        // console.log('wide');
        setResponsiveState('isWide');
    }
    if(isNarrow){
        // console.log('narrow');
        setResponsiveState('isNarrow');
    }
    if(isPhone){
        // console.log('phone');
        setResponsiveState('isPhone');
    }

},[])

useEffect(() =>{ 
    let fetchContentQuery = `*[_id == 'aitherAi']{..., 'playbackId':video.video.asset->{playbackId}, 'firstVideo':firstParagraphVideo.video.asset->{playbackId},'secondVideo':secondParagraphVideo.video.asset->{playbackId}}[0]`
    sanityClient
    .fetch(fetchContentQuery)
    .then(data => {console.log(data);setPageContent(data);
    }).catch(console.error)

},[]);

useEffect(()=>{
    console.log(secondVideoRef.current)
},[secondVideoRef.current])

const scrollHandler = (el) =>{
    if (el == 1){
        readMoreRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    if (el == 2){
        readEvenMoreRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    // window.scrollTo(0,readMoreRef.current.offsetTop - 60)
}
    

    if (!pageContent) return <></>
    // console.log(pageContent)
    return( <div>
        <div className="ai-logo">
            <img src={process.env.PUBLIC_URL + '/assets/AitherLogo_hvit.png'}/>
        </div>

        <div className="ai-intro-wrapper">
            <div className="ai-intro-content">
                <div className="ai-intro-title">
                Here comes<br/>the sun
                </div>
                <div className="ai-intro-text">
                Aither is changing the field<br/>of sun visualising technology.
                </div>
                <div
                onClick={()=>{scrollHandler(1)}}
                className="ai-intro-button">
                Learn more
                </div>
            </div>
            <div className="ai-intro-white"></div>
            <div className="ai-intro-blur"></div>
        </div>

        <div className="ai-background">
        <MuxPlayer
        
            streamType="on-demand"
            playbackId={pageContent.playbackId.playbackId}
            autoplay
            muted
            loop
            playsInline
            thumbnailTime={0}
            controls="false"
        />
        </div>
        <div
            ref={readMoreRef}
            className="ai-readmore">
            <div className="ai-readmore-section">
                <div className="ai-half">
                    <div className="ai-readmore-title">
                        {pageContent.firstParagraphTitle}
                    </div>
                    <BlockContent
                    blocks={pageContent.firstParagraphText}
                    projectId={sanityClient.clientConfig.projectId}
                    dataset={sanityClient.clientConfig.dataset}
                />
                </div>
                <div className="ai-half">
                    <div className="ai-readmore-video-wrapper">
                    <MuxPlayer
                        width={(window.innerWidth / 2) - 40}
                        height={(window.innerWidth / 2) - 40}
                        streamType="on-demand"
                        playbackId={pageContent.firstVideo.playbackId}
                        autoplay
                        muted
                        loop
                        playsInline
                        thumbnailTime={0}
                        controls="false"
                    />
                    <div
                        onClick={()=>{scrollHandler(2)}}
                        className="ai-intro-button">
                        Discover the potential
                    </div>
                    </div>
                </div>
            </div>
            <div 
            ref={readEvenMoreRef}
            className="ai-readmore-section ai-blue">
                <div className="ai-half">
                <div className="ai-readmore-video-wrapper">
                    <MuxPlayer
                        className="ai-mux-player"
                        ref={secondVideoRef}
                        
                        onCanPlay={()=>console.log('can play')}
                        width={(window.innerWidth / 2) - 40}
                        height={(window.innerWidth / 2) - 40}
                        streamType="on-demand"
                        playbackId={pageContent.secondVideo.playbackId}
                        autoplay
                        muted
                        loop
                        playsInline
                        thumbnailTime={0}
                        controls="false"
                    />
                    <a className="ai-intro-button" href='mailto:post@aither.no'>
                
                            Get in touch
                     
                    </a>
                    </div>
                </div>
                <div className="ai-half">
                    {/* <div className="ai-readmore-title">
                        {pageContent.firstParagraphTitle}
                    </div> */}
                    <BlockContent
                    blocks={pageContent.secondParagraphText}
                    projectId={sanityClient.clientConfig.projectId}
                    dataset={sanityClient.clientConfig.dataset}
                />
                
                </div>
            </div>

        </div>
        <div className="ai-logo-wrapper">
            <div className="ai-logo-title">
                Aither is in proud collaboration with:
            </div>
            <div className="ai-logo-row">
                    <div className="ai-single-logo">
                        <img src={process.env.PUBLIC_URL + '/assets/Ai-Logo_01.png'}/>
                    </div>
                    <div className="ai-single-logo">
                        <img src={process.env.PUBLIC_URL + '/assets/Ai-Logo_02.png'}/>
                    </div>
                    <div className="ai-single-logo">
                        <img src={process.env.PUBLIC_URL + '/assets/Ai-Logo_03.png'}/>
                    </div>
            </div>
        </div>
        <div className="ai-footer">
            2023 Aither AS · <a href='mailto:post@aither.no'>Kontakt</a>
        </div>
        </div>
    )
}

export default AitherAi;