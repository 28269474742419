import React, {useEffect, useState, useRef} from "react";
import { useParams } from "react-router-dom";
import sanityClient from "../../client.js";
// import { useMediaQuery } from 'react-responsive';
import {isChrome, isMobile} from 'react-device-detect'

// probably that it's chrome desktop but it's using the mobile version of video player

import ReactPlayer from 'react-player'

import './VideoSlider.style.scss';
import ReactSlider from 'react-slider';

const VideoSlider = ({videoUrl}) =>{

    // console.log(videoUrl)

    const [initSliderValue, setInitSliderValue] = useState(72);
    const videoRef = useRef();
    const videoBlobRef = useRef();
    const [orderData, setOrderData] = useState(null);
    const {slug} = useParams();
    const [videoHasLoaded, setVideoHasLoaded] = useState(false)
    const [videoFramesReady, toggleVideoFramesReady] = useState(false)
    const [videoWidth, setVideoWidth] = useState(window.innerWidth)
    const [videoHeight, setVideoHeight] = useState(window.innerWidth / 1.77)

// console.log('mobile ' + isMobile)
// console.log('chrome ' + isChrome)

useEffect(()=>{
    if (videoUrl == null || videoFramesReady == true || videoBlobRef == undefined || isMobile)return
    // console.log('not loaded')
    var req = new XMLHttpRequest();
    req.open('GET', videoUrl, true);
    req.responseType = 'blob';

    req.onload = function() {
    // Onload is triggered even on 404
    // so we need to check the status code
    if (this.status === 200) {
        var videoBlob = this.response;
        var vid = URL.createObjectURL(videoBlob); // IE10+
        // console.log('loaded')
        videoRef.current.src = vid;
        videoRef.current.currentTime = initSliderValue / 30;
        setVideoHasLoaded(true)
    }
    }
    req.onerror = function() {
    // Error
    }

    req.send();

    // extractFramesFromVideo(orderData.embedVideoFileUrl);

},[orderData])

useEffect(() => {
    if (typeof window === 'undefined') {
        return;
    }

    function setVideoSize() {
        setVideoWidth(window.innerWidth)
        setVideoHeight(window.innerWidth / 1.77)
    }

    window.addEventListener('resize', setVideoSize);

    // This is likely unnecessary, as the initial state should capture
    // the size, however if a resize occurs between initial state set by
    // React and before the event listener is attached, this
    // will just make sure it captures that.
    setVideoSize();

    // Return a function to disconnect the event listener
    return () => window.removeEventListener('resize', setVideoSize);
}, [])

// useEffect(() => {
//     // console.log('fetching')
//     sanityClient
//     .fetch(
//         `*[slug.current == "${slug}"]{ 
//             title,
//             subtitle,
//             "embedVideoFileUrl":embedVideoFile.asset->url
//         }`
//     )
//     .then((data) => setOrderData(data[0]))
//     .catch(console.error);
// }, []);

const videoReadyHandler = (e) =>{
    if (!videoHasLoaded){
        setVideoHasLoaded(true)
        videoRef.current.seekTo(initSliderValue / 30, 'seconds')   
    }
}

const sliderValueChangeHandler = (newVal) =>{
    videoRef.current.currentTime = newVal / 30
}

const sliderValueChangeHandlerMobile = (newVal) =>{
    videoRef.current.seekTo(newVal / 30, 'seconds')
}


if (!videoUrl) return <></>
    return( 
        <div className={`videoslider-wrapper ${isMobile ? 'mobile-videoslider-wrapper':''}`}>
                <div className="video-wrapper">
                    {isMobile ?
                    <ReactPlayer
                        className={`video-element show-video`}
                        ref={videoRef}
                        volume={0}
                        muted
                        
                        playsInline
                        autoPlay
      
                        onReady={(e)=>videoReadyHandler(e)}
                        url={videoUrl}
                        width={videoWidth}
                        height={videoHeight}
                    />
                    :
                        <video
                            playsInline
                            muted
                            className={`video-element ${videoHasLoaded ? 'show-video':''}`}
                            ref={videoRef}
                        />
                    }
                    <div className="video-loader-wrapper">
                        <div className="video-loader-element">
                            <img src={'https://aither.no/assets/Aither_hvit_icon.png'}/>
                        </div>
                        <div className="video-loader-tag">Laster solforholdsbilde...</div>
                    </div>

                    <div className={`slider-wrapper ${videoHasLoaded ? 'show-slider':''} ${isMobile ? 'mobile-slider-wrapper':''}`}>
                        {!isMobile && videoHasLoaded ?
                        <ReactSlider
                        max={144}
                        min={0}
                        defaultValue={72}
                        className="horizontal-slider"
                        thumbClassName="thumb-sun"
                        trackClassName="track"
                        src={videoUrl}
                        onChange={(e) => sliderValueChangeHandler(e)}
                        renderThumb={(props, state) => {
                            let width = (window.innerWidth);
                            // console.log(width)
                            let factor = (state.value / 1.77) * (width / 100);
                            let style = {backgroundSize: `${width}px`, backgroundPosition: `-${factor}px`}
                            return(
                            <div 
                            className='timer-value' 
                            // {...props.style={backgroundPosition: `-${state.value}%`}}
                            {...props} 
                            >
                                <div className="thumb-sun-icon" 
                                style={style}
                                ></div>
                            </div>
                            )}}
                        />
                        : videoHasLoaded ?
                        <ReactSlider
                            max={144}
                            min={0}
                            defaultValue={72}
                            className="horizontal-slider mobile-slider"
                            thumbClassName="thumb-sun"
                            trackClassName="track"
                            src={videoUrl}
                            onChange={(e) => sliderValueChangeHandlerMobile(e)}
                            renderThumb={(props, state) => {
                                let width = (window.innerWidth);
                                // console.log(width)
                                let factor = (state.value / 1.77) * (width / 100);
                                let style = {backgroundSize: `${width}px`, backgroundPosition: `-${factor}px`}
                                return(
                                <div 
                                className='timer-value' 
                                // {...props.style={backgroundPosition: `-${state.value}%`}}
                                {...props} 
                                >
                                    <div className="thumb-sun-icon"
                                        style={style}
                                    ></div>
                                </div>
                                )}}
                        />
                        :
                        ''
                    }
                    {isMobile? 
                        <>
                        <div className={`slider-numbers-wrapper mobile-slider-numbers-wrapper`}>
                            <div className="slider-number-left">00:00</div>
                            <div className="slider-number-center">12:00</div>
                            <div className="slider-number-right">24:00</div>
                        </div>
                    </>
                    :
                    <>
                    <div className={`slider-numbers-wrapper`}>
                            <div className="slider-number-left">00:00</div>
                            <div className="slider-number-center">06:00</div>
                            <div className="slider-number-center">12:00</div>
                            <div className="slider-number-center">18:00</div>
                            <div className="slider-number-right">24:00</div>
                        </div>
                        <div className={`slider-dots-wrapper`}>
                            <div></div>
                            <div className="slider-dot"></div>
                            <div className="slider-dot"></div>
                            <div className="slider-dot"></div>
                            <div className="slider-dot"></div>
                            <div className="slider-dot"></div>
                            <div></div>
                            <div className="slider-dot"></div>
                            <div className="slider-dot"></div>
                            <div className="slider-dot"></div>
                            <div className="slider-dot"></div>
                            <div className="slider-dot"></div>
                            <div></div>
                            <div className="slider-dot"></div>
                            <div className="slider-dot"></div>
                            <div className="slider-dot"></div>
                            <div className="slider-dot"></div>
                            <div className="slider-dot"></div>
                            <div></div>
                            <div className="slider-dot"></div>
                            <div className="slider-dot"></div>
                            <div className="slider-dot"></div>
                            <div className="slider-dot"></div>
                            <div className="slider-dot"></div>
                            <div></div>
                        </div>
                        </>
                    }
                        
                        <div className="slider-background-white"></div>
                        <div className="slider-background-blur"></div>
                    </div>
                </div>
        </div>
    )
}

export default VideoSlider;