import React, {useMemo,useCallback,useEffect, useState} from "react";
import {PortableText} from '@portabletext/react'
import sanityClient from "../../client.js";
import {useDropzone} from 'react-dropzone';
import { Form, Field } from "react-final-form";
import { useMediaQuery } from 'react-responsive';
// import AutoComplete from 'places-autocomplete-react'
import Autocomplete from "react-google-autocomplete";
// import GooglePlacesAutocomplete from 'react-google-places-autocomplete';


// import { useInput } from '../../Hooks/input-hook.js'
import './upload.styles.scss';

//https://console.cloud.google.com/google/maps-apis/credentials?project=live-location-te-1557489766897
// API key: AIzaSyDNdSXjlnQpiGOcOPHfzxiBcGdewzPudzo

const required = (value) => (value ? undefined : "!");

const Upload = () =>{
    const [pageContent, setPageContent] = useState(null)
    const [uploadedImagePreview, setUploadedImagePreview] = useState(null);
    const [uploadedImageContent, setUploadedImageContent] = useState(null);
    const [contactInfo, setContactInfo] = useState({navn: 'default', gate: 'default', postnummer: 'default', sted:'default', epost: 'default', telefon:'default'});
    const [submitButtonMessage, setSubmitButtonMessage] = useState('Send inn');
    const [uploadSuccess, setUploadSuccess] = useState(null);
    const [imageStatus, setImageStatus] = useState('waiting');
    const [coordinates, setCoordinates] = useState(null)

    const [responsiveState, setResponsiveState] = useState(null);

const isWide = useMediaQuery({
    query: '(min-width: 1100px)'
})

const isNarrow = useMediaQuery({
    query: '(max-width: 1100px)'
})

const isPhone = useMediaQuery({
    query: '(max-width: 800px)'
})

useEffect(() =>{

    if(isWide){
        console.log('wide');
        setResponsiveState('isWide');
    }
    if(isNarrow){
        console.log('narrow');
        setResponsiveState('isNarrow');
    }
    if(isPhone){
        console.log('phone');
        setResponsiveState('isPhone');
    }

},[])

useEffect(() =>{ 
    let fetchContentQuery = `*[_id == 'content']{uploadText}[0]`
    sanityClient
    .fetch(fetchContentQuery)
    .then(data => {console.log(data);setPageContent(data);
    }).catch(console.error)

},[]);

    const uploadStatusHandler = (status) =>{
      if (status == 'uploading'){
        console.log('uploading')
        setSubmitButtonMessage('Laster opp...')
      }
      if (status == 'success'){
        console.log('uploading')
        setUploadSuccess(true);
        setUploadedImagePreview(null);
      }
    }

    const handleMissingImageError = () =>{
      setImageStatus('missing')
    }

    const submitContentToState = (values) =>{

      setContactInfo(values);

      const convertBase64 = (blob) =>{
        let reader = new FileReader();
        reader.onload = function () {
          //let b64 = reader.result.replace(/^data:.+;base64,/, '');
          let b64 = reader.result;
          let filetype;

          const currentDate = new Date();
          const timestamp = currentDate.getTime(); 

          //console.log(b64);

          if(b64.includes("jpeg;")){filetype = '.jpeg'}
          if(b64.includes("jpg;")){filetype = '.jpg'}
          if(b64.includes("png;")){filetype = '.png'}

          let imageContent = {
            b64: b64,
            filetype:filetype,
            timestamp:timestamp
          }
          
          setUploadedImageContent(imageContent);
        };

        reader.readAsDataURL(blob);

      }
      if(imageStatus == 'submitted'){
        fetch(uploadedImagePreview.image)
        .then(res => res.blob())
        .then(blob => convertBase64(blob))
      } else{
        handleMissingImageError()
      }
    }

    useEffect(() =>{
      if (uploadedImageContent && contactInfo){ 

        let uploadedData = {
          imageBase64: uploadedImageContent.b64,
          imageFiletype: uploadedImageContent.filetype,
          imageName: uploadedImageContent.timestamp,
          navn: contactInfo.navn,
          gate: contactInfo.gate,
          postnummer: contactInfo.postnummer,
          sted: contactInfo.sted,
          epost: contactInfo.epost,
          telefon: contactInfo.telefon,
        }

        uploadStatusHandler('uploading');

        fetch('../../email.php', {
          method: 'post',
          mode: "same-origin",
          credentials: "same-origin",
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(uploadedData)
          })
          .then((response) => response.json())
          .then((responseData) => {
                if(responseData.message == "ok"){

                  uploadStatusHandler('success');
                  
                }
                if(responseData.message == "error"){
                    console.log("error");
                }
          })  
      }
    },[uploadedImageContent, contactInfo])

    const baseStyle = {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: 1,
        borderRadius: 2,
        borderColor: '#0098FF',
        borderStyle: 'dashed',
        backgroundColor: '#ffffff',
        color: '#0098FF',
        outline: 'none',
        transition: 'border .1s ease-in-out',
        cursor: 'pointer'
      };
      
      const activeStyle = {
        borderColor: '#33ADFF',
        backgroundColor: '#D2EDFF'
      };
      
      const acceptStyle = {
        borderColor: '#33ADFF'
      };
      
      const rejectStyle = {
        borderColor: '#cccccc'
      };
      
      const img = {
        display: 'block',
        width: 'auto',
        height: '100%'
      };
      
      function StyledDropzone(props) {
        const [files, setFiles] = useState([]);

        const onDrop = useCallback(acceptedFiles => {
          let blobUrl = URL.createObjectURL(acceptedFiles[0]);

          let imagePreview ={
            image: blobUrl,
            title: acceptedFiles[0].name
          }
          setImageStatus('submitted');
          setUploadedImagePreview(imagePreview)
            
          }, [])

        const {
          getRootProps,
          getInputProps,
          isDragActive,
          isDragAccept,
          isDragReject
        } = useDropzone({accept: 'image/*',maxFiles:1,onDrop});
      
        const style = useMemo(() => ({
          ...baseStyle,
          ...(isDragActive ? activeStyle : {}),
          ...(isDragAccept ? acceptStyle : {}),
          ...(isDragReject ? rejectStyle : {})
        }), [
          isDragActive,
          isDragReject,
          isDragAccept
        ]);
      
        return (

            <div className="picture-upload-container">
                <div className='picture-upload-description'>
                    Bilde av eiendommen
                </div>
                
                <div {...getRootProps({style})}>
                <input {...getInputProps()} />

                <div className='preview-wrapper'>
                {/* {imageStatus === 'submitted' && uploadedImagePreview ?
                    <>
                        <div className='image-preview-wrapper'>
                        <img
                            src={uploadedImagePreview.image}
                        />
                        </div>
                        <div className='title-preview-wrapper'>
                        {uploadedImagePreview.title}
                        </div>
                    </>
                    :
                    ''
                } */}
                {imageStatus === 'waiting' && coordinates !== null ? 
                    <p>Slipp bildet her, eller klikk for å laste opp</p> 
                    :
                    ''
                }
                {imageStatus === 'missing' && coordinates !== null ?
                    <p className='error'>Bildet mangler. Vennligst prøv å laste det opp på nytt</p> 
                    :
                    ''
                }
                {coordinates == null || coordinates == undefined ?
                    <p>Vennligst oppgi addressen din før du laster opp bildet.</p> 
                    :
                    ''
                }
                
                </div>
                </div>
            </div>
         
        );
      }

      let formData = {
    
      };

      const onSubmit = (values) => {
        submitContentToState(values);
      };

      useEffect(()=>{
        if (coordinates == undefined || coordinates == null)return;

        console.log(coordinates)
        //https://suncurves.com/sitefinder/horizon/?country=NO&lon=5.32596&lat=60.39093&hag=0&date=2022%2F03%2F21

          // let url = `https://suncurves.com/sitefinder/horizon/?country=NO&lon=${coordinates.lng}&lat=${coordinates.lat}&hag=0&date=2022%2F03%2F21`;
          let url ='https://suncurves.com/sitefinder/horizon/?country=NO&lon=5.32596&lat=60.39093&hag=0&date=2022%2F03%2F21'
          
          var request = new XMLHttpRequest();
          // console.log("Inside getData method");

          request.onreadystatechange = function() {
              if (this.readyState == 4 && this.status == 200) {
                  console.log(this.responseText);
              }
          };
          request.open('GET', url, true);
          request.send();
          console.log(request.responseText);
          
          fetch(url,{
            method: 'GET',
            // mode:'no-cors',
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(response => response.json())
          .then(data => console.log(data));

          const fetchData = async () => {
            try {
              const response = await fetch(url, {
                method: 'GET',
                mode:'no-cors',
                headers: {
                  'Content-Type': 'application/json'
                }
              });
              const result = await response.json();
              // const {results} = json;
              console.log(result)
            } catch (error) {
              console.log("error", error);
            }
          };

          fetchData();

      },[coordinates])

      if (!pageContent) return <></>
    return( <div>
        <div className="upload-header-wrapper">
        <div className="upload-logo">
            <img src={process.env.PUBLIC_URL + '/assets/AitherLogo_hvit.png'}/>
        </div>
        {/* <div className="upload-title">
            Kom i gang med Aither
        </div> */}
    </div>
        <div className='Upload-section-wrapper'>
           {responsiveState === 'isWide' ?
           <>
            <div className='text'>
            {/* <GooglePlacesAutocomplete
              apiKey="AIzaSyDNdSXjlnQpiGOcOPHfzxiBcGdewzPudzo"
              // selectProps={{
              //   // value,
              //   onChange: setValue,
              // }}
            /> */}
            
            {/* <AutoComplete 
              placesKey="AIzaSyDNdSXjlnQpiGOcOPHfzxiBcGdewzPudzo"
              inputId="address"
              setAddress={(addressObject) => console.log(addressObject)}
              required={true}
            /> */}
                <PortableText
                    value={pageContent.uploadText}
                />
          <div className="">
            <Autocomplete
              apiKey={'AIzaSyDNdSXjlnQpiGOcOPHfzxiBcGdewzPudzo'}
              onPlaceSelected={(place) => {
                let lat = place.geometry.location.lat();
                let lng = place.geometry.location.lng();
                let currentCoordinates = {'lat':lat,'lng':lng}
                setCoordinates(currentCoordinates)
              }}
              options={{
                types: ["address"],
                fields: ['geometry.location']
              }}
            />
        </div>  
        <div className="">Last opp bildet nedenfor</div>
            </div>
            <div className='forms'>
              {uploadedImagePreview ? 
                <div className="forms-image-preview">
                    <img
                        src={uploadedImagePreview.image}
                    />
                </div>
                :''
              }
              {uploadSuccess ?
              <div className='success-message'>Takk for henvendelsen! Vi tar kontakt så fort som mulig.</div>
              :
              <>
                <StyledDropzone />
              </>
              }
              <div className="">
              <Form
                  onSubmit={onSubmit}
                  initialValues={formData}
                  render={({ handleSubmit, form, submitting, pristine, values }) => (
                    <form onSubmit={handleSubmit}>
                      <Field name="navn" validate={required}>
                        {({ input, meta }) => (
                          <div>
                            <input {...input} type="text" placeholder="Navn" />
                            {meta.error && meta.touched && <span className='input-error'>{meta.error}</span>}
                          </div>
                        )}
                      </Field>
                      <div className='input-half-size-wrapper'>
                        <Field name="epost" validate={required}>
                          {({ input, meta }) => (
                            <div className='input-half-size'>
                              <input {...input} type="text" placeholder="Epost-addresse" />
                              {meta.error && meta.touched && <span className='input-error'>{meta.error}</span>}
                            </div>
                          )}
                        </Field>
                        <Field name="telefon" validate={required}>
                          {({ input, meta }) => (
                            <div className='input-half-size'>
                              <input {...input} type="text" placeholder="Telefon-nummer" />
                              {meta.error && meta.touched && <span className='input-error'>{meta.error}</span>}
                            </div>
                          )}
                        </Field>
                      </div>
                      <div>
                        <button className='submit-button' type="submit" disabled={submitButtonMessage == 'Laster opp...'}>
                          {submitButtonMessage}
                        </button>
                      </div>
                    </form>
                  )}
                />
                </div>
            </div>
            </>
            :
            <>
            <div className='text'>
                <PortableText
                    value={pageContent.uploadText}
                />
            </div>
            <div className='forms'>
              {uploadSuccess ?
              <div className='success-message'>Takk for henvendelsen! Vi tar kontakt så fort som mulig.</div>
              :
              <>
                <StyledDropzone />
                <div className='input-wrapper'>
                <Form
                  
                  onSubmit={onSubmit}
                  initialValues={formData}
                  render={({ handleSubmit, form, submitting, pristine, values }) => (
                    <form onSubmit={handleSubmit}>
                      <Field name="navn" validate={required}>
                        {({ input, meta }) => (
                          <div>
                            <input {...input} type="text" placeholder="Navn" />
                            {meta.error && meta.touched && <span className='input-error'>{meta.error}</span>}
                          </div>
                        )}
                      </Field>
                      <div className='input-half-size-wrapper'>
                        <Field name="epost" validate={required}>
                          {({ input, meta }) => (
                            <div className='input-half-size'>
                              <input {...input} type="text" placeholder="Epost-addresse" />
                              {meta.error && meta.touched && <span className='input-error'>{meta.error}</span>}
                            </div>
                          )}
                        </Field>
                        <Field name="telefon" validate={required}>
                          {({ input, meta }) => (
                            <div className='input-half-size'>
                              <input {...input} type="text" placeholder="Telefon-nummer" />
                              {meta.error && meta.touched && <span className='input-error'>{meta.error}</span>}
                            </div>
                          )}
                        </Field>
                      </div>
                      <Field name="gate" validate={required}>
                        {({ input, meta }) => (
                          <div>
                            <input {...input} type="text" placeholder="Gatenavn og husnummer" />
                            {meta.error && meta.touched && <span className='input-error'>{meta.error}</span>}
                          </div>
                        )}
                      </Field>
                      <div className='input-half-size-wrapper'>
                        <Field name="postnummer" validate={required}>
                          {({ input, meta }) => (
                            <div className='input-half-size'>
                              <input {...input} type="text" placeholder="Postnummer" />
                              {meta.error && meta.touched && <span className='input-error'>{meta.error}</span>}
                            </div>
                          )}
                        </Field>
                        <Field name="sted" validate={required}>
                          {({ input, meta }) => (
                            <div className='input-half-size'>
                              <input {...input} type="text" placeholder="Sted" />
                              {meta.error && meta.touched && <span className='input-error'>{meta.error}</span>}
                            </div>
                          )}
                        </Field>
                      </div>
                      <div>
                      <button className='submit-button' type="submit" disabled={submitButtonMessage == 'Laster opp...'}>
                          {submitButtonMessage}
                        </button>
                      </div>
                    </form>
                  )}
                />
                </div>
              </>
              }
            </div>
            </>
            }
        </div>

        <div className="upload-footer">
            2022 Aither AS · <a href='post@aither.no'>Kontakt</a>
        </div>
        <div className="upload-background"></div>
        </div>
    )
}

export default Upload;