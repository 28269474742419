import React, {useEffect, useState, useRef} from "react";
// import BlockContent from "@sanity/block-content-to-react";
import { useParams } from "react-router-dom";
// import sanityClient from "../../client.js";
import { useMediaQuery } from 'react-responsive';

import './Outsourced.style.scss';

// import VideoSlider from '../../Components/VideoSlider/VideoSlider.component'

const Outsourced = () =>{
console.log('outsourced');
const [responsiveState, setResponsiveState] = useState(null);
const [orderData, setOrderData] = useState(null);
const {slug} = useParams();

const isPhone = useMediaQuery({
    query: '(max-width: 900px)'
})

useEffect(() =>{
    if(isPhone){
        setResponsiveState('isPhone');
    }
},[])

// useEffect(() => {
//     sanityClient
//     .fetch(
//         `*[slug.current == "${slug}"]{ 
//             title,
//             subtitle,
//             introText,
//             embedDescriptionText,
//             "embedVideoFileUrl":embedVideoFile.asset->url,
//             popupDescriptionText,
//             downloadVideoText,
//             "downloadVideoFileUrl":downloadVideoFile.asset->url,
//             betaText,
//         }`
//     )
//     .then((data) => setOrderData(data[0]))
//     .catch(console.error);
// }, []);

    // Sections

const IntroSection = ()=>{
    return (
        <div className='section'>
            {/* <div className='meta-section no-border'>
                <div className='meta-content'>
                Aither sun diagram
                </div>
            </div> */}
            <div className="property-title">
                Aither Sun diagram
            </div>
            <div className="property-subtitle">
                Step by step process
            </div>
            <div className='paragraph'>
                This is an overview of the production steps behind the Aither sun diagram.
            {/* <BlockContent
                blocks={orderData.introText}
                projectId={sanityClient.clientConfig.projectId}
                dataset={sanityClient.clientConfig.dataset}
            /> */}
            </div>
        </div>
    )
}

const First = ()=>{
    return (
        <div className='section'>
            <div className='meta-section'>
                <div className='meta-content'>
                Step 1 - Photograph
                </div>
            </div>
            <div className='paragraph'>
            The process starts with the customer submitting a single photograph of their property, as well as the address or geographical location.
            </div>
            <div className="image">
                <img src={process.env.PUBLIC_URL + '/assets/outsourced/Outsourced_1.jpg'}/>
            </div>
        </div>
    )
}

const Second = ()=>{
    return (
        <div className='section background'>
            <div className='meta-section'>
                <div className='meta-content'>
                Step 2 – image processing and 3D-model
                </div>
            </div>
            <div className='paragraph'>
            This is the step you’ll be involved with. Based on the photograph you’ll process the image and recreate a 3D model of the property, as described in the briefing document. 
            </div>
            <div className="image">
                <img src={process.env.PUBLIC_URL + '/assets/outsourced/STEP2.png'}/>
            </div>
        </div>
    )
}

const Third = ()=>{
    return (
        <div className='section'>
            <div className='meta-section'>
                <div className='meta-content'>
                Step 3 – calculating sun conditions
                </div>
            </div>
            <div className='paragraph'>
            Based on the address of the property we can obtain the position of the sun for any given time of the day, for any day of the year. This data is being used to generate an interactive model of the sun conditions.
            </div>
            <div className="image">
                <img src={process.env.PUBLIC_URL + '/assets/outsourced/Outsourced_3_2.jpg'}/>
            </div>
        </div>
    )
}

const Fourth = ()=>{
    return (
        <div className='section'>
            <div className='meta-section'>
                <div className='meta-content'>
                Step 4 – shipping products to customer
                </div>
            </div>
            <div className='paragraph'>
            We use the sun diagram model to produce a range of end-products for the customer. They’ll receive a link with an interactive sun diagram of their property, as well as a version of the sun diagram that can be embedded on their own website. We also produce a timelapse-video of the property which can be shared on social media.            </div>
            <div className="image">
                <img src={process.env.PUBLIC_URL + '/assets/outsourced/Outsourced_4.jpg'}/>
            </div>
            <div style={{marginTop: '50px'}}>
                <a className='product-link' href='https://aither.no/Order/Gartnerlokka-21' style={{marginRight: '20px'}}>
                    <div className='link-wrapper'>
                    Final product page
                    </div>
                </a>
                <a className='product-link' href='https://mygame.page/gartnerlokka-21'>
                    <div className='link-wrapper'>
                    Interactive sun diagram
                    </div>
                </a>
            </div>
        </div>
    )
}

const Footer = ()=>{
    return (
        <div className='footer'>
            Aither 2021
        </div>
    )
}
    return( 
    <div className="product-page">
        <div className="top-bar">
            <div className="logo">
                <img src={process.env.PUBLIC_URL + '/assets/Aither_logo.png'}/>
            </div>
        </div>

        <div className="content">
            <IntroSection/>
            <First/>
            <Second/>
            <Third/>
            <Fourth/>
            <Footer/>
        </div>
        <div className='blue-background'></div>
    </div>
    )
}

export default Outsourced;