import React, {useEffect, useState, useRef} from "react";
import { useParams } from "react-router-dom";
import sanityClient from "../../client.js";

// import './order.style.scss';

import VideoSlider from '../../Components/VideoSlider/VideoSlider.component'

const Embed = () =>{

const [orderData, setOrderData] = useState(null);
const {slug} = useParams();
// console.log(slug)

useEffect(() => {
    sanityClient
    .fetch(
        `*[slug.current == "${slug}"]{ 
            "embedVideoFileUrl":embedVideoFile.asset->url,
        }`
    )
    .then((data) => setOrderData(data[0]))
    .catch(console.error);
}, []);


if (!orderData) return <></>

return (
    <VideoSlider videoUrl={orderData.embedVideoFileUrl}/>
)
}

export default Embed;