import React, {useEffect, useState, useRef} from "react";
import { useMediaQuery } from 'react-responsive';
// import './VideoSlider.style.scss';
import './FeviktoppenOutput.style.scss';

import ReactSlider from 'react-slider';
import { animated, useSpring } from 'react-spring';
import styled from 'styled-components'

const VideoMobile = styled.video`
max-width: 100%;
max-height: 100%;
-o-object-fit: contain;
object-fit: contain;
-webkit-appearance: none;
`;

const FeviktoppenOutput = () =>{
    const [initSliderValue, setInitSliderValue] = useState(144);
    const [timerValue, setTimerValue] = useState('00:00'); //etterhvert sette denne automatisk i forhold til hvor startposisjonen skal være
    const [sliderValue, setSliderValue] = useState(144);
    const videoRef = useRef();

    const returnClockFormat = (val) =>{
        const roundedVal = Math.floor(val);
        const hoursDecimal = val / 12;
        const hours = Math.floor(hoursDecimal);
        const minutes = Math.floor((hoursDecimal - hours) * 60);

        const extraZeroHour= ()=>{
            if((hours - 10) < 0 ){
                return '0';
            } else{
                return '';
            }
        };
        const extraZeroMinute= ()=>{
            if(minutes < 10){
                return '0';
            } else{
                return '';
            }
        };
        return `${extraZeroHour()}${hours}:${extraZeroMinute()}${minutes}`
    }

    const sliderValueChangeHandler = (newVal) =>{
        videoRef.current.currentTime = newVal / 30
        setTimerValue(returnClockFormat(newVal))
    }

    const videoReadyHandler = () =>{
        setTimeout(function(){
            videoRef.current.pause();
        },100)
        videoRef.current.currentTime = initSliderValue / 30;
    }

    const afterChangeHandler = () =>{
        videoRef.current.pause();
    }

    useEffect(()=>{
      videoRef.current.currentTime = initSliderValue / 30;
      videoRef.current.pause();
      setTimerValue(returnClockFormat(initSliderValue))
    },[])


    return( 
    <div className="output-page">
        <div className='navigation-wrapper'>
            <div className="address-wrapper">
                <div className="meta">
                    Soldiagram fra 22. juni
                </div>
                <div className='title'>Utsikten Fevik</div>
                <div className='subtitle'>Feviktoppen 4, 4870 Fevik</div>
            </div>
            
            <div className='slider-wrapper'>
                <div className="meta">
                    Velg klokkeslett

                    <div className='powered-by'>
                        <a href='mailto:post@aither.no'>
                            Powered by <span className='signature-wrapper'> <img src={process.env.PUBLIC_URL + '/assets/Aither_logo.png'}/> </span>
                        </a>
                    </div>
                </div>
                <ReactSlider
                    max={288}
                    defaultValue={initSliderValue}
                    className="horizontal-slider"
                    thumbClassName="thumb"
                    trackClassName="track"
                    onChange={(e) => sliderValueChangeHandler(e)}
                    renderThumb={(props, state) => <div className='timer-value' {...props}>{timerValue}</div>}
                />
            </div>
        </div>
        <div className='video-wrapper'>
            <VideoMobile
                ref={videoRef} 
                autoPlay
                playsInline
                muted 
                onLoadedMetadata={()=>videoReadyHandler()}
                src={process.env.PUBLIC_URL + '/assets/Feviktoppen_02.mp4'}
                type="video/mp4" 
            />
        </div>

        <div className='powered-by-mobile'>
            <a href='mailto:post@aither.no'>
                Powered by <span className='signature-wrapper'> <img src={process.env.PUBLIC_URL + '/assets/Aither_logo.png'}/> </span>
            </a>
        </div>
    </div>
    )
}

export default FeviktoppenOutput;