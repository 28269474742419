import React from "react";
import BlockContent from "@sanity/block-content-to-react";
import sanityClient from "../../client.js";
import imageUrlBuilder from "@sanity/image-url";

import './Contact.style.scss';

// import reneFoto from '../../Img/Aither_bilde_1.jpg';
// import atleFoto from '../../Img/Aither_bilde_2.jpg';
// import karstenFoto from '../../Img/Aither_bilde_3.jpg';

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}

const Contact = ({content, responsiveState}) =>{
    return( 
    <div className='contact-section-wrapper'>
        <div className='anchor' id='Kontakt'></div>
        {responsiveState === 'isWide' ?
        <>
            <div className='portraits-wrapper'>
                <div className='portrait'>
                    <div className='photo'>
                        <img src={urlFor(content.atlePhoto)} />
                    </div>
                    <div className='info'>
                        <BlockContent
                            blocks={content.atleText}
                            projectId={sanityClient.clientConfig.projectId}
                            dataset={sanityClient.clientConfig.dataset}
                        />
                    </div>
                </div>
                <div className='portrait'>
                    <div className='photo'>
                        <img src={urlFor(content.karstenPhoto)} />
                    </div>
                    <div className='info'>
                        <BlockContent
                            blocks={content.karstenText}
                            projectId={sanityClient.clientConfig.projectId}
                            dataset={sanityClient.clientConfig.dataset}
                        />
                    </div>
                </div>
                <div className='portrait'>
                    <div className='photo'>
                        <img src={urlFor(content.remiPhoto)} />
                    </div>
                    <div className='info'>
                        <BlockContent
                            blocks={content.remiText}
                            projectId={sanityClient.clientConfig.projectId}
                            dataset={sanityClient.clientConfig.dataset}
                        />
                    </div>
                </div>
            </div>
            <div className='text'>
                <div className='meta'>
                    Kontakt
                </div>
                    <BlockContent
                        blocks={content.contactText}
                        projectId={sanityClient.clientConfig.projectId}
                        dataset={sanityClient.clientConfig.dataset}
                    />
                <a href='mailto:post@aither.no' className='no-style justify-self'>
                    <div className='button dark margin-top'>
                        Kontakt oss
                    </div>
                </a>
            </div>
        </>
        :
        <>
            <div className='text'>
                <div className='meta'>
                    Kontakt
                </div>
                    <BlockContent
                        blocks={content.contactText}
                        projectId={sanityClient.clientConfig.projectId}
                        dataset={sanityClient.clientConfig.dataset}
                    />
                <a href='mailto:post@aither.no' className='no-style justify-self'>
                    <div className='button dark margin-top'>
                        Kontakt oss
                    </div>
                </a>
            </div>
            <div className='portraits-wrapper'>
                <div className='portrait'>
                    <div className='photo'>
                        <img src={urlFor(content.atlePhoto)} />
                    </div>
                    <div className='info'>
                        <BlockContent
                            blocks={content.atleText}
                            projectId={sanityClient.clientConfig.projectId}
                            dataset={sanityClient.clientConfig.dataset}
                        />
                    </div>
                </div>
                <div className='portrait'>
                    <div className='photo'>
                        <img src={urlFor(content.karstenPhoto)} />
                    </div>
                    <div className='info'>
                        <BlockContent
                            blocks={content.karstenText}
                            projectId={sanityClient.clientConfig.projectId}
                            dataset={sanityClient.clientConfig.dataset}
                        />
                    </div>
                </div>
                <div className='portrait'>
                    <div className='photo'>
                        <img src={urlFor(content.remiPhoto)} />
                    </div>
                    <div className='info'>
                        <BlockContent
                            blocks={content.remiText}
                            projectId={sanityClient.clientConfig.projectId}
                            dataset={sanityClient.clientConfig.dataset}
                        />
                    </div>
                </div>
            </div>
        </>
        }
    </div>
)

}

export default Contact;