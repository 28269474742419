import React, { useEffect, useState, useRef } from "react";
import BlockContent from "@sanity/block-content-to-react";
import sanityClient from "../../client.js";
import ReactPlayer from 'react-player'
// import { Scrollchor,easeOutQuad } from 'react-scrollchor';

// import VideoSlider from '../../Components/VideoSlider/VideoSlider.component'
import './Intro.style.scss';
import styled from "styled-components";

const Video = styled.video`
width: 100%;
height: 100%;
-o-object-fit: cover;
object-fit: cover;
-webkit-appearance: none;
`;

const Intro = ({content, responsiveState}) =>{

    console.log(content.videoUrl);
    return( 
        <>
    <div className='intro-section-wrapper'>
       
        <div className='content'>
        <div className='logo'>
            <img src={process.env.PUBLIC_URL + '/assets/AitherLogo_hvit.png'}/>
        </div>
            <div className='text'>
                <BlockContent
                    blocks={content.frontpageText}
                    projectId={sanityClient.clientConfig.projectId}
                    dataset={sanityClient.clientConfig.dataset}
                />
                <a href='mailto:karsten@aither.no'><div className="button dark margin-top">Kontakt oss</div></a>
            </div>
        </div>
        <div className='intro-video-wrapper'>
            {/* <video width='100%'
                height='100%' controls muted={true} playing={true} playsinline={true} loop={true} src={content.videoUrl}  /> */}
                <ReactPlayer
                width='100%'
                height='100%'
                volume={0} muted={true} playing={true} playsinline={true} loop={true} url={content.videoUrl} />
{/*                 
                <div className="video-shadow">
                    
                </div> */}
        </div>
       
    </div>
    <div className="background-blue"></div>
    </>
    )
}

export default Intro;