import React, {useEffect, useState, useRef} from "react";
import BlockContent from "@sanity/block-content-to-react";
import { useLocation } from 'react-router-dom'
import { useParams } from "react-router-dom";
import sanityClient from "../../client.js";
import { useMediaQuery } from 'react-responsive';

import ReactPlayer from 'react-player'

import './popup.style.scss';
import ReactSlider from 'react-slider';
import styled from 'styled-components'
// import Canvas from "./canvas.jsx";

const VideoMobile = styled.video`
max-width: 100%;
max-height: 100%;
-o-object-fit: contain;
object-fit: contain;
-webkit-appearance: none;
`;

const Popup = () =>{

    const [initSliderValue, setInitSliderValue] = useState(72);
    const [timerValue, setTimerValue] = useState('00:00'); //etterhvert sette denne automatisk i forhold til hvor startposisjonen skal være
    const [sliderValue, setSliderValue] = useState(72);
    const videoRef = useRef();
    const videoBlobRef = useRef();
    const [sliderPosition, setSliderPosition] = useState('ON')
    const [responsiveState, setResponsiveState] = useState(null);
    const [orderData, setOrderData] = useState(null);
    const {slug} = useParams();
    const [videoHasLoaded, setVideoHasLoaded] = useState(false)

    const [videoFrames, setVideoFrames] = useState([])
    const [videoFramesReady, toggleVideoFramesReady] = useState(false)

const isPhone = useMediaQuery({
    query: '(max-width: 900px)'
})

// let location = useLocation();
// console.log(location)

// useEffect(() =>{
//     console.log('checking if is phone')
//     if(isPhone){
//         setResponsiveState('isPhone');
//     }
// },[])

useEffect(()=>{
    if (orderData == null || videoFramesReady == true || videoBlobRef == undefined || isPhone)return
    // console.log('not loaded')
    var req = new XMLHttpRequest();
    req.open('GET', orderData.embedVideoFileUrl, true);
    req.responseType = 'blob';

    req.onload = function() {
    // Onload is triggered even on 404
    // so we need to check the status code
    if (this.status === 200) {
        var videoBlob = this.response;
        var vid = URL.createObjectURL(videoBlob); // IE10+
        console.log(vid)
        // setBlobUrl(vid)
        // Video is now downloaded
        // and we can set it as source on the video element

        videoRef.current.src = vid;
        videoRef.current.currentTime = initSliderValue / 30;
        setVideoHasLoaded(true)
        // videoRef.current.play()
    }
    }
    req.onerror = function() {
    // Error
    }

    req.send();

    console.log(orderData)

    if('sliderPosition' in orderData){
        if(orderData.sliderPosition == 'UNDER'){
            setSliderPosition('UNDER')

        }
    }


},[orderData])

useEffect(() => {
    console.log('fetching')
    sanityClient
    .fetch(
        `*[slug.current == "${slug}"]{ 
            title,
            subtitle,
            sliderPosition,
            "embedVideoFileUrl":embedVideoFile.asset->url
        }`
    )
    .then((data) => setOrderData(data[0]))
    .catch(console.error);
}, []);

const videoReadyHandler = (e) =>{
    setVideoHasLoaded(true)
    videoRef.current.seekTo(initSliderValue / 30, 'seconds') 
}

const sliderValueChangeHandler = (newVal) =>{
    videoRef.current.currentTime = newVal / 30
}

const sliderValueChangeHandlerMobile = (newVal) =>{
    videoRef.current.seekTo(newVal / 30, 'seconds')
}

// useEffect(()=>{
//     console.log('init popup')
// },[])

// if (!location.pathname.includes('Embed')) return <></>
if (!orderData) return <></>
    return( 
        <div className={`popup-output-page slider-under
        ${sliderPosition == 'UNDER' ? 'slider-under' : ''}
        `}>
                <div className="video-wrapper">
                    {isPhone ?
                    <ReactPlayer
                        className={`video-element show-video`}
                        ref={videoRef}
                        volume={0}
                        muted
                        
                        playsInline
                        autoPlay
      
                        onReady={(e)=>videoReadyHandler(e)}
                        url={orderData.embedVideoFileUrl}
                        width={window.innerWidth}
                        height={window.innerWidth / 1.77}
                    />
                    :
                        <video
                            playsInline
                            muted
                            className={`video-element ${videoHasLoaded ? 'show-video':''}`}
                            ref={videoRef}
                        />
                    }
                    <div className="video-loader-wrapper">
                        <div className="video-loader-element">
                            <img src={process.env.PUBLIC_URL + 'assets/Aither_hvit_icon.png'}/>
                        </div>
                        <div className="video-loader-tag">Laster solforholdsbilde...</div>
                    </div>

                    <div className={`slider-wrapper ${videoHasLoaded ? 'show-slider':''}`}>
                        {!isPhone && videoHasLoaded ?

                        <ReactSlider
                        max={144}
                        min={0}
                        defaultValue={72}
                        className="horizontal-slider"
                        thumbClassName="thumb-sun"
                        trackClassName="track"
                        src={orderData.embedVideoFileUrl}
                        onChange={(e) => sliderValueChangeHandler(e)}
                        renderThumb={(props, state) => {
                            // console.log()
                            let width = (window.innerHeight - 170) * 1.78;
                            // let factor = (state.value * 0.694) * ((width - 170) / 100);
                            let factor = (state.value * 0.694) * ((width - 170) / 100);
                            // console.log(factor)
                            let style = {backgroundPosition: `-${factor}px`}
                            return(
                            <div 
                            className='timer-value' 
                            // {...props.style={backgroundPosition: `-${state.value}%`}}
                            {...props} 
                            >
                                <div className="thumb-sun-icon" style={style}></div>
                            </div>
                            )}}
                        />
                        : videoHasLoaded ?
                        <ReactSlider
                            max={144}
                            min={0}
                            defaultValue={initSliderValue}
                            className="horizontal-slider"
                            thumbClassName="thumb-sun"
                            trackClassName="track"
                            src={orderData.embedVideoFileUrl}
                            onChange={(e) => sliderValueChangeHandlerMobile(e)}
                            renderThumb={(props, state) => {
                                // console.log()
                                let width = (window.innerWidth);
                                // let factor = (state.value * 0.694) * ((width - 170) / 100);
                                let factor = (state.value * 1.5) * (width / 100);
                                // console.log(factor)
                                let style = {backgroundPosition: `-${factor}px`}
                                return(
                                <div 
                                className='timer-value' 
                                // {...props.style={backgroundPosition: `-${state.value}%`}}
                                {...props} 
                                >
                                    <div className="thumb-sun-icon" style={style}></div>
                                </div>
                                )}}
                        />
                        :
                        ''
                    }
                        
                        <div className="slider-numbers-wrapper">
                        <div className="slider-number-left">00:00</div>
                            <div className="slider-number-center">06:00</div>
                            <div className="slider-number-center">12:00</div>
                            <div className="slider-number-center">18:00</div>
                            <div className="slider-number-right">24:00</div>
                        </div>
                        <div className="slider-dots-wrapper">
                            <div></div>
                            <div className="slider-dot"></div>
                            <div className="slider-dot"></div>
                            <div className="slider-dot"></div>
                            <div className="slider-dot"></div>
                            <div className="slider-dot"></div>
                            
                            <div></div>
                            <div className="slider-dot"></div>
                            <div className="slider-dot"></div>
                            <div className="slider-dot"></div>
                            <div className="slider-dot"></div>
                            <div className="slider-dot"></div>
                            
                            <div></div>
                            <div className="slider-dot"></div>
                            <div className="slider-dot"></div>
                            <div className="slider-dot"></div>
                            <div className="slider-dot"></div>
                            <div className="slider-dot"></div>
                            
                            <div></div>
                            <div className="slider-dot"></div>
                            <div className="slider-dot"></div>
                            <div className="slider-dot"></div>
                            <div className="slider-dot"></div>
                            <div className="slider-dot"></div>
                            
                            <div></div>
                        </div>
                        <div className="slider-background-white"></div>
                        <div className="slider-background-blur"></div>
                    </div>
                </div>
        </div>
    )
}

export default Popup;