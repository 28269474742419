import React, {useState, useEffect} from "react";
import BlockContent from "@sanity/block-content-to-react";
import sanityClient from "../../client.js";
import imageUrlBuilder from "@sanity/image-url";

import './Collaborators.styles.scss';


const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}

const returnedWidth = (url) =>{
    // console.log(url)
    if (url == 'https://nordiceasy.no'){
        return {width: '200px'}
    } else {
        return {}
    }
}

const Collaborators = ({content, responsiveState}) =>{
    // console.log(content)

    return( 
    <div className='collaborators-section-wrapper'>
        <div className='collaborators-section-top-background'></div>
        <div className='collaborators-section-title'>
            Våre samarbeidspartnere
        </div>
        <div className="collaborators-section-logos">
            {content.map(item =>{
                // console.log(item)
                return <>
                <a href={item.url} key={item._id} style={returnedWidth(item.url)}>
                    <img src={urlFor(item.asset).width(300).url()}/>
                </a>
                </>
            })}
        </div>
        <div className="footer">
            2022 Aither AS &middot; <a href="mailto:post@aither.no">Kontakt</a>
        </div>
    </div>
)

}

export default Collaborators;