import React, { useEffect, useState } from "react";
import sanityClient from "../../client.js";
import { useMediaQuery } from 'react-responsive';
import './Jotun.styles.scss';

import {BsPalette2} from 'react-icons/bs';

import VideoSliderJotun from "../../Components/VideoSliderJotun/VideoSliderJotun.component.jsx";

const Intro = ({toggleShowPopup}) =>{
    return (
        <div className="jotun-intro-wrapper">
            <div
            onClick={()=>{toggleShowPopup(true)}}
            className="jotun-intro-popup-button">
                <span><BsPalette2/></span>Velg farge
            </div>
            <div
                className="jotun-intro-background"
                // style={{backgroundImage: process.env.PUBLIC_URL + '/assets/finn.jpg'}}
            >
                <img src={process.env.PUBLIC_URL + '/assets/finn_02.jpg'} />
            </div>
        </div>
    )
}

const Popup = ({showPopup, toggleShowPopup}) =>{

    const [pageContent, setPageContent] = useState(null)
    const [activeColor, setActiveColor] = useState({col:null, url:null})

    useEffect(() =>{ 
        let fetchContentQuery = `*[_id == "fargevalg"]{
            "videofilOriginal":videofilOriginal.asset->url,
            "videofilTunhovd":videofilTunhovd.asset->url,
            "videofilNott":videofilNott.asset->url,
            "videofilFrostroyk":videofilFrostroyk.asset->url,
        }`
        sanityClient
        .fetch(fetchContentQuery)
        .then(data => {
            setPageContent(data[0]);
            setActiveColor({'color':'Original', 'url': data[0].videofilOriginal})
        }).catch(console.error)
    
    },[]);

    const selectColorHandler = (col) =>{
        console.log(col)
        let url;

        if (col == 'Original'){
            url = pageContent.videofilOriginal
        }
        if (col == 'Tunhovd'){
            url = pageContent.videofilTunhovd
        }
        if (col == 'Nott'){
            url = pageContent.videofilNott
        }
        if (col == 'Frostroyk'){
            url = pageContent.videofilFrostroyk
        }
        setActiveColor({'color':col, 'url':url})
    }

    // console.log(pageContent)
    if (!pageContent) return <></>
    return(
        <div className={`jotun-popup-wrapper ${showPopup ? 'show-jotun-popup' : ''}`}>
            <div className="jotun-popup-container">
                <div className="jotun-gradient-header">
                    <div className="jotun-gradient-original">

                    </div>
                    <div className="jotun-gradient-container">
                        <div
                        onClick={()=>{selectColorHandler('Original')}}
                        className={`jotun-gradient-spot jotun-original-spot ${activeColor.color == 'Original' ? 'active-icon' : ''}`}
                        >
                            Original
                        </div>

                        <div
                        onClick={()=>{selectColorHandler('Tunhovd')}}
                        className={`jotun-gradient-spot jotun-tunhovd-spot ${activeColor.color == 'Tunhovd' ? 'active-icon' : ''}`}
                        >
                            Tunhovd
                        </div>
                        <div
                        onClick={()=>{selectColorHandler('Frostroyk')}}
                        className={`jotun-gradient-spot jotun-frostroyk-spot ${activeColor.color == 'Frostroyk' ? 'active-icon' : ''}`}
                        >
                            Frostrøyk
                        </div>
                        <div
                        onClick={()=>{selectColorHandler('Nott')}}
                        className={`jotun-gradient-spot jotun-nott-spot ${activeColor.color == 'Nott' ? 'active-icon' : ''}`}
                        >
                            Nøtt
                        </div>
                        
                        <div className="jotun-gradient-cover"></div>
                    </div>

                    {/* <div className={`jotun-popup-colour jotun-original ${activeColor.color == 'Original' ? 'jotun-active-colour' : ''}`}
                    // style={{backgroundColor: 'white'}}
                    onClick={()=>{selectColorHandler('Original')}}>Original
                    <div className="active-icon"></div>   
                    </div>
                    <div className={`jotun-popup-colour jotun-tunhovd ${activeColor.color == 'Tunhovd' ? 'jotun-active-colour' : ''}`}
                    // style={{backgroundColor: 'rgb(94, 121, 134)'}}
                    onClick={()=>{selectColorHandler('Tunhovd')}}>Tunhovd
                    <div className="active-icon"></div>   
                    </div>
                    <div className={`jotun-popup-colour jotun-nott ${activeColor.color == 'Nott' ? 'jotun-active-colour' : ''}`}
                    // style={{backgroundColor: 'rgb(84, 73, 67)'}}
                    onClick={()=>{selectColorHandler('Nott')}}>Nøtt
                    <div className="active-icon"></div>   
                    </div>
                   <div className={`jotun-popup-colour jotun-frostroyk ${activeColor.color == 'Frostroyk' ? 'jotun-active-colour' : ''}`}
                    // style={{backgroundColor: 'rgb(228, 224, 211)'}}
                    onClick={()=>{selectColorHandler('Frostroyk')}}>
                        Frostrøyk
                    <div className="active-icon"></div>    
                    </div> */}

                </div>

                <div className="jotun-popup-slider-wrapper">

                    <div className="jotun-popup-slider-element">
                        {activeColor.url != null ?
                            <VideoSliderJotun fargevalg={activeColor} />
                            :''
                        }
                    </div>
                </div>
                <div className="jotun-meta">Powered by <a href='https://aither.no'>Aither</a></div>
            </div>
            <div className="jotun-popup-cover"
            onClick={()=>{toggleShowPopup(false)}}
            ></div>
        </div>
    )
}

const Jotun = () =>{

const [showPopup, toggleShowPopup] = useState(false);
const [responsiveState, setResponsiveState] = useState(null);

const isWide = useMediaQuery({
    query: '(min-width: 1100px)'
})

const isNarrow = useMediaQuery({
    query: '(max-width: 1100px)'
})

const isPhone = useMediaQuery({
    query: '(max-width: 800px)'
})

useEffect(() =>{

    if(isWide){
        // console.log('wide');
        setResponsiveState('isWide');
    }
    if(isNarrow){
        // console.log('narrow');
        setResponsiveState('isNarrow');
    }
    if(isPhone){
        // console.log('phone');
        setResponsiveState('isPhone');
    }

},[])

// useEffect(()=>{
//     console.log(showPopup)
// },[showPopup])

    return( 
    <div className='jotun-page'>
        <Popup showPopup={showPopup} toggleShowPopup={toggleShowPopup} responsiveState={responsiveState}/>
        <Intro toggleShowPopup={toggleShowPopup} />
    </div>
    )
}
export default Jotun;