import React, {useState, useEffect} from "react";
import {PortableText} from '@portabletext/react'
import sanityClient from "../../client.js";
import imageUrlBuilder from "@sanity/image-url";
import ReactPlayer from 'react-player'
import { BiCheckCircle } from "react-icons/bi";
import { TbArrowDownCircle } from "react-icons/tb";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import "animate.css/animate.min.css";

import './Slider.style.scss';

import VideoSlider from '../../Components/VideoSlider/VideoSlider.component'
import styled from 'styled-components'

const VideoMobile = styled.video`
width: 100%;
height: 100%;
-o-object-fit: cover;
object-fit: cover;
-webkit-appearance: none;
`;

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}

const Slider = ({content, responsiveState}) =>{

const myPortableTextComponents = {  
    marks: {
      link: ({children, value}) => {
        const rel = !value.href.startsWith('/') ? 'noreferrer noopener' : undefined
        return (
          <a href={value.href} rel={rel}>
            {children}
          </a>
        )
      },
    },
  }
// console.log(responsiveState)
    return( 
    <div className='slider-section-wrapper'>
      <AnimationOnScroll className="slider-anim" 
      // initiallyVisible={responsiveState == 'isPhone'} 
      offset={responsiveState == 'isPhone' ? 300 : 170} animateIn={responsiveState == 'isPhone' ? 'animate__fadeIn' : "animate__fadeIn"} animateOut="animate__fadeOutUp">
        <div className="slider-section-image-wrapper">
          <div className="slider-icon-wrapper">
            <div className="slider-image-icon-text">
              Fra foto til Solforholdsbilde
            </div>
          <div className="slider-image-icon">
            <TbArrowDownCircle/>
            </div>
          </div>
          <img src={urlFor(content.pitchVideoImage).url()} />
        </div>
      </AnimationOnScroll>
      <div className="slider-section-top-gradient"></div>
      {/* <div className="slider-section-title">
        Interaktiv videoslider
      </div> */}
         <VideoSlider videoUrl={content.videoUrl} start={27}/>
      <div className="slider-section-text">
              <PortableText
                    value={content.sliderText}
                    components={myPortableTextComponents}
                />
      </div>
    </div>
)

}

export default Slider;