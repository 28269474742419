import React, {useEffect, useState, useRef} from "react";
import BlockContent from "@sanity/block-content-to-react";
import { useParams } from "react-router-dom";
import sanityClient from "../../client.js";
import { useMediaQuery } from 'react-responsive';
import imageUrlBuilder from "@sanity/image-url";
import {BiSun as SunSymbol} from 'react-icons/bi';

import './products.style.scss';


import VideoSlider from '../../Components/VideoSlider/VideoSlider.component'
import styled from 'styled-components'

const CustomVideo = styled.video`
width: 100%;
max-width: 100%;
max-height: 100%;
-o-object-fit: contain;
object-fit: contain;
`;

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}

const Products = () =>{


const [responsiveState, setResponsiveState] = useState(null);
const [orderData, setOrderData] = useState(null);
const {slug} = useParams();

const FBVidRef = useRef();
const IGVidRef = useRef();

const [FBVidCanPlay, setFBVidCanPlay] = useState(false);
const [IGVidCanPlay, setIGVidCanPlay] = useState(false);

const [FBVidReady, setFBVidReady] = useState(false);


const isPhone = useMediaQuery({
    query: '(max-width: 900px)'
})

useEffect(() =>{
    if(isPhone){
        setResponsiveState('isPhone');
    }
},[])

useEffect(() => {
    sanityClient
    .fetch(
        `*[_id == "products"]{ 
            title,
            introText,
            embedDescriptionText,
            "embedIllustration": {"url": embedDescriptionImage.asset->url, "caption":embedDescriptionImage.caption},
            "embedVideoFileUrl":embedVideoFile.asset->url,
            downloadVideoText,
            "downloadVideoFileSquareUrl":downloadVideoFileSquare.asset->url,
            "downloadVideoFileHorizontalUrl":downloadVideoFileHorizontal.asset->url,
            betaText,
        }`
    )
    .then((data) => setOrderData(data[0]))
    .catch(console.error);
}, []);


const IntroSection = ()=>{
    return (
        <div className='section'>
            <div className='meta-section no-border'>
                <div className='meta-content'>
                Soldiagram – produktside
                </div>
            </div>
            <div className="property-title">
                {orderData.title}
            </div>
            <div className='paragraph'>
            <BlockContent
                blocks={orderData.introText}
                projectId={sanityClient.clientConfig.projectId}
                dataset={sanityClient.clientConfig.dataset}
            />
            </div>
        </div>
    )
}

const EmbedSection = ()=>{
    return (
        <div className='section'>
            <div className='meta-section'>
                <div className='meta-content'>
                Soldiagram-slider
                </div>
            </div>
            <div className="product-embed-wrapper">
                <VideoSlider videos={[orderData.embedVideoFileUrl]}/>
            </div>
            <div className='paragraph'>
            <BlockContent
                blocks={orderData.embedDescriptionText}
                projectId={sanityClient.clientConfig.projectId}
                dataset={sanityClient.clientConfig.dataset}
            />
            </div>
        </div>
    )
}

const PopupSection = ()=>{
    return (
        <div className='section'>
            <div className='meta-section'>
                <div className='meta-content'>
                Popup
                </div>
            </div>
            {orderData.popupDescriptionText ?
                            <div className='paragraph'>
                            <BlockContent
                                blocks={orderData.popupDescriptionText}
                                projectId={sanityClient.clientConfig.projectId}
                                dataset={sanityClient.clientConfig.dataset}
                            />
                            </div>
                :
                ''
            }
            <div className='product-embed-image-wrapper'>
                <div className='image'>
                    <img src={orderData.embedIllustration.url} />
                </div>
                <div className='caption'>
                    {orderData.embedIllustration.caption}
                </div>
            </div>
            <a className='product-link' target="_blank" href={`https://www.aither.no/Produkter/Popup`}>
                <div className='link-wrapper'>
                    <div className='symbol'>
                        <SunSymbol />
                    </div>
                    <div className='string'>
                    Besøk soldiagram popup
                    </div>
                </div>
            </a>
        </div>
    )
}

const BetaSection = ()=>{
    return (
        <div className='section'>
            <div className='meta-section'>
                <div className='meta-content'>
                Ekstra
                </div>
            </div>
            <div className='paragraph'>
            <BlockContent
                blocks={orderData.betaText}
                projectId={sanityClient.clientConfig.projectId}
                dataset={sanityClient.clientConfig.dataset}
            />
            </div>
        </div>
    )
}


const DownloadSection = ()=>{
    return (
        <div className='section'>
            <div className='meta-section'>
                <div className='meta-content'>
                    Nedlastbar video
                </div>
            </div>
            <div className='paragraph'>
            <BlockContent
                blocks={orderData.downloadVideoText}
                projectId={sanityClient.clientConfig.projectId}
                dataset={sanityClient.clientConfig.dataset}
            />
            </div>
            <div className='download-video-wrapper-70'>
                {/* {responsiveState == 'isPhone' ?  */}
                    <CustomVideo
                    className='video-native'
                    controls
                    playsInline
                    muted 
                    src={orderData.downloadVideoFileHorizontalUrl}
                    poster={process.env.PUBLIC_URL + '/assets/FB_mockup_thumb_web.jpg'}
                    type="video/mp4" 
                    />
                    {/* :
                    <div className='video' onClick={() =>{setFBVidCanPlay(!FBVidCanPlay)}}>
                        {!FBVidCanPlay ?
                        <div className='play-button-wrapper'>
                            <div className='round-button light'> 
                                <div className='symbol'>
                                    <PlaySymbol />
                                </div>
                            </div>
                        </div>
                        :''}
                        <div className={`video-still ${FBVidCanPlay ? 'video-ready' : '' }`}>
                            <img src={process.env.PUBLIC_URL + '/assets/FB_mockup_thumb_web.jpg'} />
                        </div>

                        <ReactPlayer 
                        url={[orderData.downloadVideoFileHorizontalUrl]} 
                        playing={FBVidCanPlay}
                        width='100%'
                        height='auto'
                        />
                        
                    </div>
                    } */}
                <div className='caption'>
                    En horisontal video til deling på facebook
                </div>
            </div>
            <div className='download-video-wrapper-50'>
                {/* {responsiveState == 'isPhone' ?  */}
                    <CustomVideo
                    className='video-native'
                    controls
                    playsInline
                    muted 
                    src={orderData.downloadVideoFileSquareUrl}
                    poster={process.env.PUBLIC_URL + '/assets/IG_mockup_thumb_web.jpg'}
                    type="video/mp4" 
                    />
                    {/* :
                    <div className='video' onClick={() =>{setIGVidCanPlay(!IGVidCanPlay)}}>
                        {!IGVidCanPlay ?
                        <div className='play-button-wrapper'>
                            <div className='round-button light'> 
                                <div className='symbol'>
                                    <PlaySymbol />
                                </div>
                            </div>
                        </div>
                        :''}
                        <div className={`video-still ${IGVidCanPlay ? 'video-ready' : '' }`}>
                            <img src={process.env.PUBLIC_URL + '/assets/IG_mockup_thumb_web.jpg'} />
                        </div>

                        <ReactPlayer 
                        url={[orderData.downloadVideoFileSquareUrl]} 
                        playing={IGVidCanPlay}
                        width='100%'
                        height='auto'
                        />
                        
                    </div>
                    } */}
                <div className='caption'>
                    En kvadratisk video til deling på Instagram
                </div>
            </div>
        
        </div>
    )
}

const Footer = ()=>{
    return (
        <div className='footer'>
            Aither 2022
        </div>
    )
}
if (!orderData) return <></>
    return( 
    <div className="product-page">
        <div className="top-bar">
            <div className="logo">
                <img src={process.env.PUBLIC_URL + '/assets/Aither_logo.png'}/>
            </div>
        </div>

        <div className="content">
            <IntroSection/>
            <EmbedSection/>
            <PopupSection/>
            <DownloadSection/>
            <BetaSection/>
            <Footer/>
        </div>
        <div className='blue-background'></div>
    </div>
    )
}

export default Products;