import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import './App.css';
import './App.scss';

import Placeholder from "./Pages/Placeholder.page";
import Frontpage from "./Pages/Frontpage.page";

import OutputPage from "./Pages/OutputPage.page";
import PirenEmbed from "./Pages/PirenEmbed.page";
import PirenDev from "./Pages/PirenDev.page";

import Feviktoppen from "./Pages/Feviktoppen.page";
import FeviktoppenEmbed from "./Pages/FeviktoppenEmbed.page";
import FeviktoppenOutput from "./Pages/FeviktoppenOutput.page";
import Baarsrudveien from "./Pages/Baarsrudveien/Baarsrudveien.page";

import Outsourced from "./Pages/Outsourced.page";

import products from "./Pages/Order/products.page";
import productPopup from "./Pages/Order/productPopup.page";

import prosess from "./Pages/General/ProcessNO.page";
import process from "./Pages/General/ProcessEN.page";

import jotun from "./Pages/Jotun/Jotun.page";
import otovo from "./Pages/Otovo/Otovo.page";
import solcellepanel from "./Pages/Otovo/Solcellepanel.page";

import order from "./Pages/Order/order.page";
import popup from "./Pages/Order/popup_2.page";
import embed from "./Pages/Order/embed.page";
import Upload from "./Pages/Upload/upload.page";

import AitherAi from "./Pages/AitherAi/aitherAi.page";



function App() {
  return (
    <Router>
      <div>
        <Route component={Frontpage} path="/" exact />
        <Route component={Frontpage} path="/Prototype/" exact/>

        <Route component={OutputPage} path="/Kongspangsvei-49" exact />
        <Route component={PirenEmbed} path="/Prototype/Piren-embed/" exact />
        <Route component={PirenDev} path="/Prototype/Piren-dev" exact/>

        {/* <Route component={Feviktoppen} path="/Order/Utsikten-Fevik/"/>
        <Route component={FeviktoppenEmbed} path="/Embed/Utsikten-Fevik/"/>
        <Route component={FeviktoppenOutput} path="/Utsikten-Fevik/"/> */}

        <Route component={Baarsrudveien} path="/Baarsrudveien-44/" exact/>
        <Route component={Outsourced} path="/Outsourced/" exact/>

        <Route component={products} path="/Produkter/" exact/>
        <Route component={productPopup} path="/Produkter/Popup" exact/>
        
        <Route component={prosess} path="/Prosess/" exact/>
        <Route component={Upload} path="/Upload/" exact/>

        <Route exact path="/365/Orreveien-5b" render={() => {window.location.href="/365/Orreveien-5b/"}} />

        <Route component={jotun} path="/Maren/" exact/>
        <Route component={solcellepanel} path="/Solcellepanel/" exact/>
        <Route component={otovo} path="/Solcellepanel/Otovo" exact/>

        <Route component={popup} path="/:slug" exact/>
        <Route component={order} path="/Order/:slug" exact/>
        <Route component={embed} path="/Embed/:slug" exact/>

        <Route component={AitherAi} path="/ai" exact/>
  
      </div>
    </Router>
  );
}

export default App;
