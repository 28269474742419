import React, {useState, useEffect} from "react";
import {PortableText} from '@portabletext/react'
import sanityClient from "../../client.js";
import imageUrlBuilder from "@sanity/image-url";
import ReactPlayer from 'react-player'
import { TbHeartHandshake } from "react-icons/tb";
import { TbSun } from "react-icons/tb";
import { TbReportMoney } from "react-icons/tb";

import './Pitch.style.scss';

import VideoSlider from '../../Components/VideoSlider/VideoSlider.component'
import styled from 'styled-components'

const VideoMobile = styled.video`
width: 100%;
height: 100%;
-o-object-fit: cover;
object-fit: cover;
-webkit-appearance: none;
`;

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

const Pitch = ({content, responsiveState}) =>{

    const [pitchVidState, setPitchVidState] = useState(null)
    const [vidsCanPlay, setVidsCanPlay] = useState(false)

useEffect(() =>{
    if (pitchVidState === 'ready'){
        setVidsCanPlay(true);
    }
},[pitchVidState])

const myPortableTextComponents = {  
    marks: {
      link: ({children, value}) => {
        const rel = !value.href.startsWith('/') ? 'noreferrer noopener' : undefined
        return (
          <a href={value.href} rel={rel}>
            {children}
          </a>
        )
      },
    },
  }
  console.log(content)

    return( 
    <div className='pitch-section-wrapper'>
        <div className='anchor' id='OmAither'></div>
          <div className='text'>
                <div className='meta'>
                    Hvorfor Aither?
                </div>

                <PortableText
                    value={content.pitchText}
                    components={myPortableTextComponents}
                />
          </div>
          <div className="pitch-points-wrapper">
            {content.pitchArray.map((punktObj, index)=>{
              // console.log(punktObj.pitchArrayText)
              let icon;
              if (index == 0){
                icon = <TbHeartHandshake/>
              }
              if (index == 1){
                icon = <TbReportMoney/>
              }
              if (index == 2){
                icon = <TbSun/>
              }
              return (<div className="pitch-point" key={`pitch${index}`}>
                <div className="pitch-point-icon">{icon}</div>
                <div className="pitch-point-text">
                  <PortableText
                    value={punktObj.pitchArrayText}
                    
                  />
                </div>
                </div>
              )
            })}
          </div>
{/* 
          <div className='strek-wrapper'>
            <div className='left-strek'></div>
            <div className='middle-strek'></div>
            <div className='right-strek'></div>
          </div> */}
    </div>
)

}

export default Pitch;