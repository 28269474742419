import React, {useEffect, useState, useRef} from "react";
import { useMediaQuery } from 'react-responsive';
import './FeviktoppenEmbed.style.scss';

import ReactSlider from 'react-slider';
import styled from 'styled-components'

const VideoMobile = styled.video`
width: 100%;
height: 100%;
-o-object-fit: cover;
object-fit: cover;
-webkit-appearance: none;
`;

const OutputPage = () =>{
    const [initSliderValue, setInitSliderValue] = useState(144);
    const [timerValue, setTimerValue] = useState('00:00'); //etterhvert sette denne automatisk i forhold til hvor startposisjonen skal være
    const [sliderValue, setSliderValue] = useState(144);
    const videoRef = useRef();

    const returnClockFormat = (val) =>{
        const roundedVal = Math.floor(val);
        const hoursDecimal = val / 12;
        const hours = Math.floor(hoursDecimal);
        const minutes = Math.floor((hoursDecimal - hours) * 60);

        const extraZeroHour= ()=>{
            if((hours - 10) < 0 ){
                return '0';
            } else{
                return '';
            }
        };
        const extraZeroMinute= ()=>{
            if(minutes < 10){
                return '0';
            } else{
                return '';
            }
        };
        return `${extraZeroHour()}${hours}:${extraZeroMinute()}${minutes}`
    }

    const sliderValueChangeHandler = (newVal) =>{
        videoRef.current.currentTime = newVal / 30
        setTimerValue(returnClockFormat(newVal))
    }

    const videoReadyHandler = () =>{
        setTimeout(function(){
            videoRef.current.pause();
        },100)
        videoRef.current.currentTime = initSliderValue / 30;
    }

    const afterChangeHandler = () =>{
        videoRef.current.pause();
    }

    useEffect(()=>{
      videoRef.current.currentTime = initSliderValue / 30;
      videoRef.current.pause();
      setTimerValue(returnClockFormat(initSliderValue))
    },[])

    return( 
    <div className="embed-wrapper">
        <div className="embed">
            <div className='video-wrapper'>
                <div className='logo-wrapper'>
                    <a href='mailto:post@aither.no'>
                        <img src={process.env.PUBLIC_URL + '/assets/Aither_hvit_icon.png'}/> 
                    </a>
                </div>
                <VideoMobile
                    ref={videoRef} 
                    autoPlay
                    playsInline
                    muted 
                    onLoadedMetadata={()=>videoReadyHandler()}
                    src={process.env.PUBLIC_URL + '/assets/Feviktoppen_02.mp4'}
                    type="video/mp4" 
                />
            </div>
            <ReactSlider
                max={288}
                defaultValue={initSliderValue}
                className="horizontal-slider"
                thumbClassName="thumb"
                trackClassName="track"
                onChange={(e) => sliderValueChangeHandler(e)}
                onAfterChange={() => afterChangeHandler()}
                renderThumb={(props, state) => <div className='timer-value' {...props}>{timerValue}</div>}
            />
        </div>
    </div>
    )
}

export default OutputPage;