import React, {useEffect, useState, useRef} from "react";
import { useParams } from "react-router-dom";
import sanityClient from "../../client.js";
// import { useMediaQuery } from 'react-responsive';
import {isChrome, isMobile} from 'react-device-detect'

import {BiLeftArrowAlt} from 'react-icons/bi'
import {BiRightArrowAlt} from 'react-icons/bi'

// probably that it's chrome desktop but it's using the mobile version of video player

import ReactPlayer from 'react-player'

import './VideoSliderOtovo.style.scss';
import ReactSlider from 'react-slider';

const VideoSliderOtovo = ({fargevalg}) =>{

    // console.log(fargevalg)

    const [initSliderValue, setInitSliderValue] = useState(72);
    const videoRef = useRef();
    const videoBlobRef = useRef();
    const [showHint, setShowHint] = useState(true)
    const [videoHasLoaded, setVideoHasLoaded] = useState(false)
    const [videoFramesReady, toggleVideoFramesReady] = useState(false)
    const [videoWidth, setVideoWidth] = useState(window.innerWidth - 20)
    const [videoHeight, setVideoHeight] = useState((window.innerWidth - 20) / 1.77)
    const [currentVideoUrl, setCurrentVideoUrl] = useState()

useEffect(()=>{
    if (isMobile){
        // console.log(fargevalg);
        setCurrentVideoUrl(fargevalg.url);
        // videoRef.current.seekTo(initSliderValue / 30, 'seconds')   
        // videoRef.current.currentTime = initSliderValue / 30;
        return
    }else{
    setVideoHasLoaded(false)

    if (fargevalg.url == null || videoFramesReady == true || videoBlobRef == undefined || isMobile)return
    // console.log('not loaded')
    var req = new XMLHttpRequest();
    req.open('GET', fargevalg.url, true);
    req.responseType = 'blob';

    req.onload = function() {
    // Onload is triggered even on 404
    // so we need to check the status code
    if (this.status === 200) {
        var videoBlob = this.response;
        var vid = URL.createObjectURL(videoBlob); // IE10+
        // console.log('loaded')
        videoRef.current.src = vid;
        videoRef.current.currentTime = initSliderValue / 30;
        setVideoHasLoaded(true)
    }
    }
    req.onerror = function() {
    // Error
    }

    req.send();
}

    // extractFramesFromVideo(orderData.embedVideoFileUrl);

},[fargevalg])

useEffect(() => {
    if (typeof window === 'undefined') {
        return;
    }

    function setVideoSize() {
        setVideoWidth(window.innerWidth - 20)
        setVideoHeight((window.innerWidth - 20) / 1.77)
    }

    window.addEventListener('resize', setVideoSize);

    // This is likely unnecessary, as the initial state should capture
    // the size, however if a resize occurs between initial state set by
    // React and before the event listener is attached, this
    // will just make sure it captures that.
    setVideoSize();

    // Return a function to disconnect the event listener
    return () => window.removeEventListener('resize', setVideoSize);
}, [])

// useEffect(() => {
//     // console.log('fetching')
//     sanityClient
//     .fetch(
//         `*[slug.current == "${slug}"]{ 
//             title,
//             subtitle,
//             "embedVideoFileUrl":embedVideoFile.asset->url
//         }`
//     )
//     .then((data) => setOrderData(data[0]))
//     .catch(console.error);
// }, []);

const videoReadyHandler = (e) =>{
    // console.log('ready')
   e.seekTo(initSliderValue / 30, 'seconds')
    if (!videoHasLoaded){
        setVideoHasLoaded(true)
        videoRef.current.seekTo(initSliderValue / 30, 'seconds')   
    }
}

const sliderValueChangeHandler = (newVal) =>{
    videoRef.current.currentTime = newVal / 30
    // if(showHint){setShowHint(false)}
}

const sliderValueChangeHandlerMobile = (newVal) =>{
    videoRef.current.seekTo(newVal / 30, 'seconds')
}


if (!fargevalg) return <></>
    return( 
        <div className={`videoslider-otovo-wrapper ${isMobile ? 'mobile-videoslider-wrapper':''}`}>
                <div className="video-wrapper-otovo">
                    {isMobile ?
                    <ReactPlayer
                        key={currentVideoUrl}
                        className={`video-element-otovo show-video`}
                        ref={videoRef}
                        volume={0}
                        muted
                        playsInline
                        autoPlay
                        
                        onReady={(e)=>videoReadyHandler(e)}
                        url={currentVideoUrl}
                        width={videoWidth}
                        height={videoHeight}
                    />
                    :
                        <video
                            playsInline
                            muted
                            className={`video-element-otovo ${videoHasLoaded ? 'show-video':''}`}
                            ref={videoRef}
                        />
                    }
                    <div className="video-loader-wrapper-otovo">
                        <div className="video-loader-element">
                            <img src={'https://aither.no/assets/Aither_hvit_icon.png'}/>
                        </div>
                        {/* <div className="video-loader-tag">Laster</div> */}
                    </div>

                    <div className="video-element-otovo-placeholder">
                    <img src={process.env.PUBLIC_URL + '/assets/Otovo-placeholder.jpg'} />

                    </div>

                    <div className={`slider-wrapper-otovo ${videoHasLoaded ? 'show-slider':''} ${isMobile ? 'mobile-slider-wrapper':''}`}>
                        
                        {!isMobile && videoHasLoaded ?
                        <ReactSlider
                        max={144}
                        min={0}
                        defaultValue={72}
                        className="horizontal-slider"
                        thumbClassName="thumb-sun"
                        trackClassName="track"
                        src={fargevalg.url}
                        onBeforeChange={()=>{setShowHint(false)}}
                        onChange={(e) => sliderValueChangeHandler(e)}
                        renderThumb={(props, state) => {
                            let width = (window.innerWidth);
                            // console.log(width)
                            let factor = (state.value / 1.77) * (width / 100);
                            let style = {backgroundSize: `${width}px`, backgroundPosition: `-${factor}px`}
                            return(
                            <div 
                            className='timer-value' 
                            // {...props.style={backgroundPosition: `-${state.value}%`}}
                            {...props} 
                            >
                                    {showHint && videoHasLoaded ?
                                        <div className="slider-hint">
                                            <BiLeftArrowAlt className="slider-hint-arrow" /> Slide <BiRightArrowAlt className="slider-hint-arrow"  />
                                        </div>
                                    :''
                                    }
                                <div className="thumb-sun-icon" 
                                style={style}
                                ></div>
                            </div>
                            )}}
                        />
                        : videoHasLoaded ?
                        <ReactSlider
                            max={144}
                            min={0}
                            defaultValue={72}
                            className="horizontal-slider mobile-slider"
                            thumbClassName="thumb-sun"
                            trackClassName="track"
                            src={fargevalg.url}
                            onChange={(e) => sliderValueChangeHandlerMobile(e)}
                            renderThumb={(props, state) => {
                                let width = (window.innerWidth);
                                // console.log(width)
                                let factor = (state.value / 1.77) * (width / 100);
                                let style = {backgroundSize: `${width}px`, backgroundPosition: `-${factor}px`}
                                return(
                                <div 
                                className='timer-value' 
                                // {...props.style={backgroundPosition: `-${state.value}%`}}
                                {...props} 
                                >
                                    <div className="thumb-sun-icon"
                                        style={style}
                                    ></div>
                                </div>
                                )}}
                        />
                        :
                        ''
                    }
                        
                        {isMobile? 
                        <>
                        <div className={`slider-numbers-wrapper mobile-slider-numbers-wrapper`}>
                            <div className="slider-number-left">00:00</div>
                            <div className="slider-number-center">12:00</div>
                            <div className="slider-number-right">24:00</div>
                        </div>
                    </>
                    :
                    <>
                    <div className={`slider-numbers-wrapper`}>
                            <div className="slider-number-left">00:00</div>
                            <div className="slider-number-center">06:00</div>
                            <div className="slider-number-center">12:00</div>
                            <div className="slider-number-center">18:00</div>
                            <div className="slider-number-right">24:00</div>
                        </div>
                        <div className={`slider-dots-wrapper`}>
                            <div></div>
                            <div className="slider-dot"></div>
                            <div className="slider-dot"></div>
                            <div className="slider-dot"></div>
                            <div className="slider-dot"></div>
                            <div className="slider-dot"></div>
                            
                            <div></div>
                            <div className="slider-dot"></div>
                            <div className="slider-dot"></div>
                            <div className="slider-dot"></div>
                            <div className="slider-dot"></div>
                            <div className="slider-dot"></div>
                            
                            <div></div>
                            <div className="slider-dot"></div>
                            <div className="slider-dot"></div>
                            <div className="slider-dot"></div>
                            <div className="slider-dot"></div>
                            <div className="slider-dot"></div>
                            
                            <div></div>
                            <div className="slider-dot"></div>
                            <div className="slider-dot"></div>
                            <div className="slider-dot"></div>
                            <div className="slider-dot"></div>
                            <div className="slider-dot"></div>
                            
                            <div></div>
                        </div>
                        </>
                    }
                        <div className="slider-background-white"></div>
                        <div className="slider-background-blur"></div>
                    </div>
                </div>
        </div>
    )
}

export default VideoSliderOtovo;