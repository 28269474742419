import React, {useEffect, useState, useRef} from "react";
import BlockContent from "@sanity/block-content-to-react";
import { useParams } from "react-router-dom";
import sanityClient from "../../client.js";
import { useMediaQuery } from 'react-responsive';

import './Process.style.scss';

// import VideoSlider from '../../Components/VideoSlider/VideoSlider.component'

const ProcessNO = () =>{
const [responsiveState, setResponsiveState] = useState(null);
const [pageContent, setPageContent] = useState(null);
const {slug} = useParams();

const isPhone = useMediaQuery({
    query: '(max-width: 900px)'
})

useEffect(() =>{
    if(isPhone){
        setResponsiveState('isPhone');
    }
},[])

useEffect(() => {
    sanityClient
    .fetch(
        `*[_id == "process"]{"introTitle":introTitleNO,"introSubtitle":introSubTitleNO,"introText":introTextNO,"firstText":firstTextNO,"firstImage":firstImage.asset->url,"secondText":secondTextNO,"secondImage":secondImage.asset->url,"thirdText":thirdTextNO,"thirdImage":thirdImage.asset->url,"fourthText":fourthTextNO,"fourthImage":fourthImage.asset->url}`

    )
    .then((data) => setPageContent(data[0]))
    .catch(console.error);
}, []);

    // Sections

const IntroSection = ()=>{
    return (
        <div className='section'>
            <div className="property-title">
                {pageContent.introTitle}
            </div>
            <div className="property-subtitle">
                {pageContent.introSubtitle}
            </div>
            <div className='paragraph'>
                <BlockContent
                    blocks={pageContent.introText}
                    projectId={sanityClient.clientConfig.projectId}
                    dataset={sanityClient.clientConfig.dataset}
                />
            </div>
        </div>
    )
}

const First = ()=>{
    return (
        <div className='section'>
            <div className='meta-section'>
                <div className='meta-content'>
                    Steg 1 – motta fotografi
                </div>
            </div>
            <div className='paragraph'>
                <BlockContent
                    blocks={pageContent.firstText}
                    projectId={sanityClient.clientConfig.projectId}
                    dataset={sanityClient.clientConfig.dataset}
                />
            </div>
            <div className="image">
                <img src={pageContent.firstImage}/>
            </div>
        </div>
    )
}

const Second = ()=>{
    return (
        <div className='section'>
        <div className='meta-section'>
            <div className='meta-content'>
                Steg 2 – bildebehandling og 3D-modell
            </div>
        </div>
        <div className='paragraph'>
            <BlockContent
                blocks={pageContent.secondText}
                projectId={sanityClient.clientConfig.projectId}
                dataset={sanityClient.clientConfig.dataset}
            />
        </div>
        <div className="image">
            <img src={pageContent.secondImage}/>
        </div>
    </div>
    )
}

const Third = ()=>{
    return (
        <div className='section'>
            <div className='meta-section'>
                <div className='meta-content'>
                    Steg 3 – beregne solforhold
                </div>
            </div>
            <div className='paragraph'>
                <BlockContent
                    blocks={pageContent.thirdText}
                    projectId={sanityClient.clientConfig.projectId}
                    dataset={sanityClient.clientConfig.dataset}
                />
            </div>
            <div className="image">
                <img src={pageContent.thirdImage}/>
            </div>
        </div>
    )
}

const Fourth = ()=>{
    return (
        <div className='section'>
            <div className='meta-section'>
                <div className='meta-content'>
                    Steg 4 – produkter til kunden
                </div>
            </div>
            <div className='paragraph'>
                <BlockContent
                    blocks={pageContent.fourthText}
                    projectId={sanityClient.clientConfig.projectId}
                    dataset={sanityClient.clientConfig.dataset}
                />
            </div>
            <div className="image">
            <img src={pageContent.fourthImage}/>
            </div>
        
            <div style={{marginTop: '50px'}}>
                <a className='product-link' href='https://aither.no/Order/Gartnerlokka-21' style={{marginRight: '20px'}}>
                    <div className='link-wrapper'>
                    Ferdig produktside
                    </div>
                </a>
                <a className='product-link' href='https://mygame.page/gartnerlokka-21'>
                    <div className='link-wrapper'>
                    Interaktivt soldiagram
                    </div>
                </a>
            </div>
        </div>
    )
}

const Footer = ()=>{
    return (
        <div className='footer'>
            Aither 2022
        </div>
    )
}

if (!pageContent) return <></>
    return( 
    <div className="product-page">
        <div className="top-bar">
            <div className="logo">
                <img src={process.env.PUBLIC_URL + '/assets/Aither_logo.png'}/>
            </div>
        </div>

        <div className="content">
            <IntroSection/>
            <First/>
            <Second/>
            <Third/>
            <Fourth/>
            <Footer/>
        </div>
        <div className='blue-background'></div>
    </div>
    )
}

export default ProcessNO;