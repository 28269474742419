import React, { useEffect, useState } from "react";
import sanityClient from "../client.js";
import { useMediaQuery } from 'react-responsive';

import './Frontpage.style.scss';

import Menu from '../Components/Menu/Menu.component';

import Intro from '../Sections/Intro/Intro.section';
import Collaborators from '../Sections/Collaborators/Collaborators.section';

import Pitch from '../Sections/Pitch/Pitch.section';
import Slider from '../Sections/Slider/Slider.section';
import Upload from '../Sections/Upload/Upload.section';
import Contact from '../Sections/Contact/Contact.section';
import "animate.css/animate.min.css";


const Frontpage = () =>{

const [pageContent, setPageContent] = useState(null);
const [responsiveState, setResponsiveState] = useState(null);

const isWide = useMediaQuery({
    query: '(min-width: 1100px)'
})

const isNarrow = useMediaQuery({
    query: '(max-width: 1100px)'
})

const isPhone = useMediaQuery({
    query: '(max-width: 800px)'
})

useEffect(() =>{

    if(isWide){
        // console.log('wide');
        setResponsiveState('isWide');
    }
    if(isNarrow){
        // console.log('narrow');
        setResponsiveState('isNarrow');
    }
    if(isPhone){
        // console.log('phone');
        setResponsiveState('isPhone');
    }

},[])

useEffect(() =>{ 
    let fetchContentQuery = `{'splashPage': *[_id == 'content']{frontpageText, "videoUrl": frontpageVideo.asset->url}[0],'pitchPage': *[_id == 'content']{pitchText, pitchArray, pitchVideoImage, "videoUrl": pitchVideo.asset->url}[0],'sliderPage': *[_id == 'content']{pitchVideoImage, sliderText, "videoUrl": sliderVideo.asset->url}[0], 'collaborators': *[_id == 'content']{collaborators}[0]}`
    sanityClient
    .fetch(fetchContentQuery)
    .then(data => {setPageContent(data);
    }).catch(console.error)

},[]);
// safari bg colour: 002942
    // console.log(pageContent)
    if (!pageContent) return <></>
    return( 
    <div className='frontpage'>
        {/* <Menu/> */}
        <Intro content={pageContent.splashPage} responsiveState={responsiveState}/>
        <Pitch content={pageContent.pitchPage} responsiveState={responsiveState}/>
        <Slider content={pageContent.sliderPage} responsiveState={responsiveState}/>
        <Collaborators content={pageContent.collaborators.collaborators} responsiveState={responsiveState}/>
        {/* <Pitch content={pageContent.pitchPage} responsiveState={responsiveState}/> */}
        {/* <About content={pageContent.aboutPage} responsiveState={responsiveState}/>
        <Upload content={pageContent.uploadPage} responsiveState={responsiveState}/>
        <Contact content={pageContent.contactPage} responsiveState={responsiveState}/> */}
    </div>
    )
}
export default Frontpage;