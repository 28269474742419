import React, {useEffect, useState, useRef} from "react";

import './Baarsrudveien.style.scss';

import { animated, useSpring } from 'react-spring';

import VideoSlider from '../../Components/VideoSlider/VideoSlider.component'
import '../../Components/VideoSlider/VideoSlider.style.scss';
import ReactSlider from 'react-slider';

import styled from 'styled-components'

const NavBtn = styled.div`
cursor:pointer;
position: absolute;
width: 200px;
height: 140px;
background: url(${process.env.PUBLIC_URL +  '/assets/Baarsrudveien_nav.png'});
background-size: contain;
background-repeat: no-repeat;
background-position: top left;
z-index: 8;
top: 50%;
left: 50%;
transform: translate(-50%, -100%);
`;

const Background = styled.div`
width: 100vw;
height: 100vh;
position: relative;
background: url(${process.env.PUBLIC_URL +  '/assets/Baarsrudveien_bg_2.jpg'});
background-size: cover;
background-repeat: no-repeat;
background-position: center;
`;

const VideoMobile = styled.video`
width: 100%;
height: 100%;
-o-object-fit: cover;
object-fit: cover;
-webkit-appearance: none;
`;

const Baarsrudveien = () =>{

    const [embedIsShowing, setEmbedIsShowing] = useState(false);

    const [initSliderValue, setInitSliderValue] = useState(114);
    const [timerValue, setTimerValue] = useState('00:00'); //etterhvert sette denne automatisk i forhold til hvor startposisjonen skal være
    const [sliderValue, setSliderValue] = useState(114);
    const [videoActive, setVideoActive] = useState('var');
    const [videoLoaded, setVideoLoaded] = useState(false);
    const [sliderUntouched, setSliderUntouched] = useState(true);
    const [videoFadedOut, setVideoFadedOut] = useState(false);
    const videoRef = useRef();


    const returnClockFormat = (val) =>{
        const roundedVal = Math.floor(val);
        const hoursDecimal = val / 12;
        const hours = Math.floor(hoursDecimal);
        const minutes = Math.floor((hoursDecimal - hours) * 60);

        const extraZeroHour= ()=>{
            if((hours - 10) < 0 ){
                return '0';
            } else{
                return '';
            }
        };
        const extraZeroMinute= ()=>{
            if(minutes < 10){
                return '0';
            } else{
                return '';
            }
        };

        return `${extraZeroHour()}${hours}:${extraZeroMinute()}${minutes}`

    }

    useEffect(() =>{
        videoRef.current.currentTime = sliderValue / 30;
    },[videoActive])

    const sliderValueChangeHandler = (newVal) =>{
        videoRef.current.currentTime = newVal / 30
        setTimerValue(returnClockFormat(newVal))
    }

    const videoReadyHandler = () =>{
        setVideoLoaded(true);
        setTimeout(function(){
            videoRef.current.pause();
        },10)
        videoRef.current.currentTime = initSliderValue / 30;
    }

    useEffect(()=>{
      videoRef.current.currentTime = initSliderValue / 30;
      setTimerValue(returnClockFormat(initSliderValue))
    },[])
      
      const fadeIn = useSpring({
        opacity: embedIsShowing ? 1 : 0,
        transform: embedIsShowing ? `translate(-50%, -50%) scale(1)` : `translate(-50%, -50%) scale(0.9)`,
        config:{duration:200}
      })
      

    return( 
        <div className="demo-page">
            
                <div className={`demo-embed-wrapper ${embedIsShowing ? 'display-embed' : '' }`}>
                    <div className='closeBtn' onClick={() =>{setEmbedIsShowing(!embedIsShowing)}}>x</div>
                    <div className='powered-by'>
                        <a href='mailto:post@aither.no'>
                            Powered by <span className='signature-wrapper'> <img src={process.env.PUBLIC_URL + '/assets/Aither_logo.png'}/> </span>
                        </a>
                    </div>

                    <div className="video-slider-wrapper">
                        <div className='video-wrapper'>
                            <VideoMobile
                                ref={videoRef} 
                                autoPlay
                                playsInline
                                muted 
                                onLoadedMetadata={()=>videoReadyHandler()}
                                src={process.env.PUBLIC_URL + '/assets/Baarsrudveien.mp4'}
                                type="video/mp4" 
                            />
                            <div className='loader-wrapper'>
                                <div className='loader'>
                                <img src={process.env.PUBLIC_URL + '/assets/Aither_hvit_icon.png'}/> 
                                </div>
                            </div>
                        </div>
                    <ReactSlider
                        max={288}
                        defaultValue={initSliderValue}
                        className="horizontal-slider"
                        thumbClassName="thumb"
                        trackClassName="track"
                        onChange={(e) => sliderValueChangeHandler(e)}
                        renderThumb={(props, state) => <div className='timer-value' {...props}>{timerValue}</div>}
                    />

                    </div>

                    {/* <VideoSlider videos={[process.env.PUBLIC_URL + '/assets/Baarsrudveien.mp4']} signature={'none'}/> */}
                </div>

            <NavBtn onClick={() =>{setEmbedIsShowing(!embedIsShowing)}}>
                
            </NavBtn>
            <Background></Background>
        </div>
    )
}

export default Baarsrudveien;