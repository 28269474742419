import React, {useEffect, useState, useRef} from "react";
import BlockContent from "@sanity/block-content-to-react";
import { useParams } from "react-router-dom";
import sanityClient from "../../client.js";
import { useMediaQuery } from 'react-responsive';
import imageUrlBuilder from '@sanity/image-url'

import './order.style.scss';
import VideoSlider from '../../Components/VideoSlider/VideoSlider.component'

const builder = imageUrlBuilder(sanityClient)

function urlFor(source) {
  return builder.image(source)
}


const Order = () =>{

const [responsiveState, setResponsiveState] = useState(null);
const [orderData, setOrderData] = useState(null);
const {slug} = useParams();

const isPhone = useMediaQuery({
    query: '(max-width: 900px)'
})

useEffect(() =>{
    if(isPhone){
        setResponsiveState('isPhone');
    }
},[])

useEffect(() => {
    sanityClient
    .fetch(
        `*[slug.current == "${slug}"]{ 
            title,
            subtitle,
            introText,
            "introBildeUrl": introBilde.asset->url,
            embedDescriptionText,
            "embedVideoFileUrl":embedVideoFile.asset->url,
            popupDescriptionText,
            downloadVideoText,
            downloadVideoTextPromo,
            "downloadVideoFileUrl":downloadVideoFile.asset->url,
            downloadVideoFileArray[]{"url":file.asset->url,caption},
            "downloadVideoFilePromoUrl":downloadVideoFilePromo.asset->url,
            downloadVideoFileArrayPromo[]{"url":file.asset->url,caption},
            stillImagesArray[]{...},
            betaText,
        }`
    )
    .then((data) => setOrderData(data[0]))
    .catch(console.error);
}, []);

    // Sections

const IntroSection = ()=>{
    return (
        <div className='section'>
            <div className='meta-section no-border'>
                <div className='meta-content'>
                Soldiagram - din bestilling
                </div>
            </div>
            <div className="property-title">
                {orderData.title}
            </div>
            <div className="property-subtitle">
                {orderData.subtitle}
            </div>
            <div className='paragraph'>
            <BlockContent
                blocks={orderData.introText}
                projectId={sanityClient.clientConfig.projectId}
                dataset={sanityClient.clientConfig.dataset}
            />
            </div>
        </div>
    )
}

const IntroSectionBilde = ()=>{
    return (
        <div className='intro-bilde-section'>
            <div className="property-title-image">
            <img src={urlFor(orderData.introBildeUrl).width(Math.round(window.innerWidth/1.5)).url()}/>
                {/* <img src={orderData.introBildeUrl}/> */}
            </div>
            <div className="property-title-wrapper">
                <div className='meta-content'>
                Soldiagram - din bestilling
                </div>
                <div className="property-title">
                    {orderData.title}
                </div>
                <div className="property-subtitle">
                    {orderData.subtitle}
                </div>
                <div className='paragraph'>
                <BlockContent
                    blocks={orderData.introText}
                    projectId={sanityClient.clientConfig.projectId}
                    dataset={sanityClient.clientConfig.dataset}
                />
                </div>
            </div>
        </div>
    )
}

const EmbedSection = ()=>{
    return (
        <div className='section'>
            <div className='meta-section'>
                <div className='meta-content'>
                Embed-kode
                </div>
            </div>
            <div className="product-embed-wrapper">
                <VideoSlider videoUrl={orderData.embedVideoFileUrl} start={27}/>
            </div>
            <div className='paragraph'>
            <BlockContent
                blocks={orderData.embedDescriptionText}
                projectId={sanityClient.clientConfig.projectId}
                dataset={sanityClient.clientConfig.dataset}
            />
            </div>
            <div className='code-wrapper'>
                <code>
                {`<iframe style='width:100%;height:550px' frameborder="0" src="https://aither.no/Embed/${slug}/" scrolling="no">Iframes not supported</iframe>`}            
                </code>
            </div>
        </div>
    )
}

const PopupSection = ()=>{
    return (
        <div className='section'>
            <div className='meta-section'>
                <div className='meta-content'>
                Popup
                </div>
            </div>
            <div className='paragraph'>
            <BlockContent
                blocks={orderData.popupDescriptionText}
                projectId={sanityClient.clientConfig.projectId}
                dataset={sanityClient.clientConfig.dataset}
            />
            </div>
            <a className='product-link' target="_blank" href={`https://www.aither.no/${slug}`}>
                <div className='link-wrapper'>
                    {`https://www.aither.no/${slug}`}
                </div>
            </a>
        </div>
    )
}

const BetaSection = ()=>{
    return (
        <div className='section'>
            <div className='meta-section'>
                <div className='meta-content'>
                Beta
                </div>
            </div>
            <div className='paragraph'>
            <BlockContent
                blocks={orderData.betaText}
                projectId={sanityClient.clientConfig.projectId}
                dataset={sanityClient.clientConfig.dataset}
            />
            </div>
        </div>
    )
}

const ImageSection = ()=>{
    return (
        <div className='section'>
            <div className='meta-section'>
                <div className='meta-content'>
                Stillbilder
                </div>
            </div>
            <div className='paragraph'>Her kan du laste ned utvalgte stillbilder fra solforløpsvideoen.</div>
            <div className='image-wrapper'>
                {orderData.stillImagesArray.map((image, index)=>{
                    return <div key={index} className="still-image"><a href={urlFor(image).url()}><img src={urlFor(image).width(500).url()}/></a></div>
                })}
            </div>
        </div>
    )
}


const DownloadSection = ()=>{
    return (
        <div className='section'>
            <div className='meta-section'>
                <div className='meta-content'>
                    Nedlastbar video
                </div>
            </div>
            <div className='paragraph'>
            <BlockContent
                blocks={orderData.downloadVideoText}
                projectId={sanityClient.clientConfig.projectId}
                dataset={sanityClient.clientConfig.dataset}
            />
            </div>
            <div className='download-video-wrapper'>
                <video controls muted playsInline src={orderData.downloadVideoFileUrl} />
            </div>
            <a className='product-link' href={orderData.downloadVideoFileUrl} download>
                <div className='link-wrapper'>
                Åpne fil
                </div>
            </a>

            {orderData.downloadVideoFileArray ? 
            <div className="extra-download-videos">
                {orderData.downloadVideoFileArray.map(video=>{
                    return (
                        <div key={video.url} className="extra-download-video">
                            <div className="extra-download-video-player">
                                <video controls muted playsInline src={video.url} />
                            </div>
                            <div className="extra-download-video-caption">
                            {video.caption}
                            </div>
                            <a href={video.url} className='small product-link' download>
                            Åpne fil
                            </a>
                        </div>
                    )
                })}
            </div>
            :
            ''
            }
        
        </div>
    )
}

const DownloadPromoSection = ()=>{
    return (
        <div className='section'>
            <div className='meta-section'>
                <div className='meta-content'>
                    Nedlastbar video
                </div>
            </div>
            <div className='paragraph'>
            <BlockContent
                blocks={orderData.downloadVideoTextPromo}
                projectId={sanityClient.clientConfig.projectId}
                dataset={sanityClient.clientConfig.dataset}
            />
            </div>
            <div className='download-video-wrapper'>
                <video controls muted playsInline src={orderData.downloadVideoFilePromoUrl} />
            </div>
            <a className='product-link promo-link' href={orderData.downloadVideoFilePromoUrl} download>
                <div className='link-wrapper'>
                Åpne fil
                </div>
            </a>

            {orderData.downloadVideoFileArrayPromo ? 
            <div className="extra-download-videos">
                {orderData.downloadVideoFileArrayPromo.map(video=>{
                    return (
                        <div key={video.url} className="extra-download-video">
                            <div className="extra-download-video-player">
                                <video controls muted playsInline src={video.url} />
                            </div>
                            <div className="extra-download-video-caption">
                            {video.caption}
                            </div>
                            <a href={video.url} className='small product-link promo-link' download>
                            Åpne fil
                            </a>
                        </div>
                    )
                })}
            </div>
            :
            ''
            }
        
        </div>
    )
}

const Footer = ()=>{
    return (
        <div className='footer'>
            Aither 2022
        </div>
    )
}
if (!orderData) return <></>
console.log(orderData)
    return( 
    <div className="product-page">
        <div className="top-bar">
            <div className="logo">
                <img src={process.env.PUBLIC_URL + '/assets/Aither_logo.png'}/>
            </div>
        </div>
        {orderData.introBildeUrl && <IntroSectionBilde/> }
        <div className="content-wrapper">
            <div className="content">
                {!orderData.introBildeUrl && <IntroSection/> }
                <div className="meta-section">Interaktivt soldiagram</div>
                <EmbedSection/>
                <PopupSection/>
                <DownloadSection/>
                </div>
            </div>
        <div className="promo">
            <div className="content">
                <div className="meta-section">Promo-materiell</div>
                {orderData.stillImagesArray ? <ImageSection/> : ''}
                {orderData.downloadVideoFilePromoUrl ? <DownloadPromoSection/> : ''}
                <BetaSection/>
                <Footer/>
            </div>
        </div>
        
    </div>
    )
}

export default Order;