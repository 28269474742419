import React, { useEffect, useState } from "react";
import sanityClient from "../../client.js";
import { useMediaQuery } from 'react-responsive';
import {isMobile} from 'react-device-detect'
import './Otovo.styles.scss';

import {FaSolarPanel, FaLeaf, FaMoneyBillWave} from 'react-icons/fa';
import {IoClose} from 'react-icons/io5'
import {ImPowerCord} from 'react-icons/im'
import {BiBuildingHouse} from 'react-icons/bi'
import {BsFillSunFill} from 'react-icons/bs'

import VideoSliderOtovo from "../../Components/VideoSliderOtovo/VideoSliderOtovo.component.jsx";

const Intro = ({toggleShowPopup, responsiveState}) =>{
    return (
        <div className="otovo-intro-wrapper">
            <div
            onClick={()=>{toggleShowPopup(true)}}
            className="otovo-intro-popup-button">
                <div className="otovo-intro-popup-button-icon"><FaSolarPanel/></div><div className='otovo-intro-popup-button-text'>Vis solforhold og<br/>energipotensial</div>
            </div>
            <div
                className="otovo-intro-background"
            >
                {responsiveState == 'isPhone' ?
                 <img src={process.env.PUBLIC_URL + '/assets/Otovo-finn_red_mobile.jpg'} />
                :
                <img src={process.env.PUBLIC_URL + '/assets/Otovo-finn_red.jpg'} />
            }
               
            </div>
        </div>
    )
}

const Popup = ({responsiveState,showPopup, toggleShowPopup}) =>{

    const [pageContent, setPageContent] = useState(null)
    const [showSolcelle, toggleShowSolcelle] = useState(false)
    const [activeOption, setActiveOption] = useState('Original')
    const [offerFadeState, setOfferFadeState] = useState('fadeOut')

    useEffect(() =>{ 
        let fetchContentQuery = `*[_id == "solcellepanel"]{
            "videofilOriginal":videofilOriginal.asset->url,
            "videofilSolcelle":videofilSolcelle.asset->url,
        }`
        sanityClient
        .fetch(fetchContentQuery)
        .then(data => {
            console.log(data)
            setPageContent(data[0]);
            setActiveOption({'option':'Original', 'url': data[0].videofilOriginal})
        }).catch(console.error)
    
    },[]);


    useEffect(()=>{
        if (!pageContent) return
        if (showSolcelle){
            setActiveOption({'option':'Solcelle', 'url': pageContent.videofilSolcelle})
            window.setTimeout(()=>{
                setOfferFadeState('fadeIn')
            },300)
            
        } else{
            setActiveOption({'option':'Original', 'url': pageContent.videofilOriginal})
            setOfferFadeState('fadeOut')
        }
    },[showSolcelle])

    // console.log(responsiveState)
    if (!pageContent) return <></>
    return(
        <div className={`otovo-popup-wrapper ${showPopup ? 'show-otovo-popup' : ''}`}>
            <div className="otovo-popup-container">
                <div className="otovo-popup-header">
                    <div className='otovo-intro-wrapper'>
                        <div className="otovo-intro-element">
                            <div className="otovo-intro-icon">
                            <BiBuildingHouse/>
                            </div>
                            <div className="otovo-intro-text">
                                <b>
                            Korsholmsgate 1,<br/>
                            4015 Mandal
                            </b>
                            </div>
                        </div>
                        <div className="otovo-intro-element">
                            <div className="otovo-intro-icon">
                            <BsFillSunFill/>
                            </div>
                            <div className="otovo-intro-text">
                                {responsiveState == 'isPhone' ?
                                <>
                            Denne eiendommen<br/>
                            opplever i snitt <b>11:27</b><br/>
                            timer sol hver dag.
                                </>
                                :
                                <>
                            Denne eiendommen opplever<br/>
                            i snitt <b>11:27</b> timer sol hver dag.
                                </>
                            }
                            </div>
                        </div>
                    </div>
                    <div
                        className={`otovo-expandable-container ${showSolcelle ? 'otovo-expandable-container-expanded' : ''}`}>
                            <div
                        className={`otovo-expandable-container-gradient ${showSolcelle ? 'otovo-expandable-container-gradient-expanded' : ''}`}>

                            </div>

                        <div
                        onClick={()=>{toggleShowSolcelle(true)}}
                        className={`otovo-offer-wrapper ${offerFadeState == 'fadeIn' ? 'otovo-hide' : ''}`}>
                            <div className="otovo-offer-logo">
                                <img src={process.env.PUBLIC_URL + '/assets/otovo_logo_sirkel.png'} />
                            </div>
                            <div className="otovo-offer-text">
                                Se hvor mye du kan spare<br/>
                                med solcellepanel fra Otovo
                            </div>
                        </div>
                        <div
                        
                        className={`otovo-full-offer-wrapper ${showSolcelle ? '' : 'otovo-hide'}`}>
                            <div
                            onClick={()=>{toggleShowSolcelle(false)}}
                            className="otovo-offer-close">
                                <IoClose/>
                            </div>
                            <div className={`otovo-full-offer-points-wrapper ${offerFadeState == 'fadeOut' ? 'otovo-hide' : '' }`}>
                                <div className="otovo-full-offer-point">
                                    <div className="otovo-full-offer-point-icon icon-cyan">
                                        <FaSolarPanel/>
                                        {/* <img src={process.env.PUBLIC_URL + '/assets/otovo_logo_sirkel.png'} /> */}
                                    </div>
                                    <div className="otovo-full-offer-point-text">
                                        <div className="otovo-full-offer-point-title">
                                            Antall paneler
                                        </div>
                                        <div className="otovo-full-offer-point-value">
                                        16 standard
                                        </div>
                                    </div>
                                </div>
                                <div className="otovo-full-offer-point">
                                    <div className="otovo-full-offer-point-icon icon-yellow">
                                        <ImPowerCord/>
                                        {/* <img src={process.env.PUBLIC_URL + '/assets/otovo_logo_sirkel.png'} /> */}
                                    </div>
                                    <div className="otovo-full-offer-point-text">
                                        <div className="otovo-full-offer-point-title">
                                            Strøm
                                        </div>
                                        <div className="otovo-full-offer-point-value">
                                        6700kWh
                                        </div>
                                    </div>
                                </div>
                                <div className="otovo-full-offer-point">
                                    <div className="otovo-full-offer-point-icon icon-green">
                                        <FaLeaf/>
                                        {/* <img src={process.env.PUBLIC_URL + '/assets/otovo_logo_sirkel.png'} /> */}
                                    </div>
                                    <div className="otovo-full-offer-point-text">
                                        <div className="otovo-full-offer-point-title">
                                            Co2 spart
                                        </div>
                                        <div className="otovo-full-offer-point-value">
                                        2693kg / år
                                        </div>
                                    </div>
                                </div>
                                <div className="otovo-full-offer-point">
                                    <div className="otovo-full-offer-point-icon icon-pink">
                                        <FaMoneyBillWave/>
                                        {/* <img src={process.env.PUBLIC_URL + '/assets/otovo_logo_sirkel.png'} /> */}
                                    </div>
                                    <div className="otovo-full-offer-point-text">
                                        <div className="otovo-full-offer-point-title">
                                            Penger spart
                                        </div>
                                        <div className="otovo-full-offer-point-value">
                                        11 - 15k / år
                                        </div>
                                    </div>
                                </div>
                                <a target='_blank' href='https://solar.otovo.com/nb-no/products/99b4abd6-4c3e-4d4d-aec8-ea22fa279768'>
                                    <div className="otovo-full-offer-link-wrapper">
                                        <div className="otovo-full-offer-link-wrapper-icon">
                                            <img src={process.env.PUBLIC_URL + '/assets/otovo_logo_sirkel.png'} />
                                        </div>
                                     
                                        <div className="otovo-full-offer-link-wrapper-text">
                                            Se hele tilbudet<br/>på otovo.no
                                        </div>
                                        
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="otovo-popup-slider-wrapper">

                    <div className="otovo-popup-slider-element">
                        {activeOption.url != null ?
                            <VideoSliderOtovo fargevalg={activeOption} />
                            :''
                        }
                    </div>
                </div>
                <div className="otovo-meta">Powered by <a href='https://aither.no'>Aither</a></div>
            </div>
            <div className="otovo-popup-cover"
            onClick={()=>{toggleShowPopup(false)}}
            ></div>
        </div>
    )
}

const Otovo = () =>{

const [showPopup, toggleShowPopup] = useState(false);
const [responsiveState, setResponsiveState] = useState(null);

const isWide = useMediaQuery({
    query: '(min-width: 1100px)'
})

const isNarrow = useMediaQuery({
    query: '(max-width: 1100px)'
})

const isPhone = useMediaQuery({
    query: '(max-width: 800px)'
})

useEffect(() =>{

    if(isWide){
        // console.log('wide');
        setResponsiveState('isWide');
    }
    if(isNarrow){
        // console.log('narrow');
        setResponsiveState('isNarrow');
    }
    if(isPhone){
        // console.log('phone');
        setResponsiveState('isPhone');
    }

},[])

// useEffect(()=>{
//     console.log(showPopup)
// },[showPopup])

    return( 
    <div className='otovo-page'>
        <Popup showPopup={showPopup} toggleShowPopup={toggleShowPopup} responsiveState={responsiveState}/>
        <Intro toggleShowPopup={toggleShowPopup} responsiveState={responsiveState}/>
    </div>
    )
}
export default Otovo;