import React, {useEffect, useState, useRef} from "react";
import { useMediaQuery } from 'react-responsive';
import './PirenDev.style.scss';

import ReactSlider from 'react-slider';
import styled from 'styled-components'

const VideoMobile = styled.video`
width: 100%;
height: 100%;
-o-object-fit: cover;
object-fit: cover;
-webkit-appearance: none;
`;

const PirenDev = () =>{
    const [initSliderValue, setInitSliderValue] = useState(144);
    const [timerValue, setTimerValue] = useState('00:00'); //etterhvert sette denne automatisk i forhold til hvor startposisjonen skal være
    const [sliderValue, setSliderValue] = useState(144);
    const videoRef = useRef();
    const [responsiveState, setResponsiveState] = useState(null);

const isPhone = useMediaQuery({
    query: '(max-width: 900px)'
})

useEffect(() =>{

    if(isPhone){
        setResponsiveState('isPhone');
    }

},[])

    const returnClockFormat = (val) =>{
        const roundedVal = Math.floor(val);
        const hoursDecimal = val / 12;
        const hours = Math.floor(hoursDecimal);
        const minutes = Math.floor((hoursDecimal - hours) * 60);

        const extraZeroHour= ()=>{
            if((hours - 10) < 0 ){
                return '0';
            } else{
                return '';
            }
        };
        const extraZeroMinute= ()=>{
            if(minutes < 10){
                return '0';
            } else{
                return '';
            }
        };
        return `${extraZeroHour()}${hours}:${extraZeroMinute()}${minutes}`
    }

    const sliderValueChangeHandler = (newVal) =>{
        videoRef.current.currentTime = newVal / 30
        setTimerValue(returnClockFormat(newVal))
    }

    const videoReadyHandler = () =>{
        console.log('ready');
        setTimeout(function(){
            videoRef.current.pause();
        },100)
        videoRef.current.currentTime = initSliderValue / 30;
    }

    useEffect(()=>{
    //   videoRef.current.defaultMuted = true;
    //   videoRef.current.muted = true;
      videoRef.current.currentTime = initSliderValue / 30;
      setTimerValue(returnClockFormat(initSliderValue))
    },[])

    return( 
    <div className="embed-wrapper">
        <div className="embed">
            <div className='video-wrapper'>
                <div className='logo-wrapper'>
                    <img src={process.env.PUBLIC_URL + '/assets/Aither_hvit_icon.png'}/> 
                </div>
                <VideoMobile
                    ref={videoRef} 
                    autoPlay
                    playsInline
                    muted 
                    onLoadedMetadata={()=>videoReadyHandler()}
                    src={process.env.PUBLIC_URL + '/assets/Piren_embed_01.mp4'}
                    type="video/mp4" 
                />
            </div>
            <ReactSlider
                max={288}
                defaultValue={initSliderValue}
                className="horizontal-slider"
                thumbClassName="thumb"
                trackClassName="track"
                onChange={(e) => sliderValueChangeHandler(e)}
                renderThumb={(props, state) => <div className='timer-value' {...props}>{timerValue}</div>}
            />
        </div>
    </div>
    )
}

export default PirenDev;